import { faPaperclip, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface Field {
  investor_id: string;
  receiver_name: string;
  invoice_id: string;
  amount: string;
  mode_of_payment: string;
  transaction_no: string;
}

const AddPayment: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [country, setCountry] = useState<Common[]>([]);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [investorInvoice, setInvestorInvoice] = useState<any[]>([]);
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const [fields, setFields] = useState<Field>({
    investor_id: '',
    invoice_id: '',
    receiver_name: '',
    amount: '',
    mode_of_payment: '',
    transaction_no: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  useEffect(() => {
    getSupplier();
    getInvestorInvoice();
  }, []);

  const getSupplier = async () => {
    const response = await getService('investor');
    if (response.status === 200) {
      setSupplierList(response.data);
    }
  };

  const getInvestorInvoice = async () => {
    const response = await getService('investor_invoice');
    if (response.status === 200) {
      setInvestorInvoice(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSupplierDetails(id);
    }
  }, [id]);

  const fetchSupplierDetails = async (id: any) => {
    const response = await getService(`show_investor_invoice_payment_id/${id}`);
    if (response.status === 200) {
      setFields(prevFields => ({
        ...prevFields,
        investor_id: response.data.investor_id,
        receiver_name: response.data.receiver_name,
        invoice_id: response.data.invoice_id,
        amount: response.data.amount,
        mode_of_payment: response.data.mode_of_payment,
        transaction_no: response.data.transaction_no
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    if (id) {
      setLoading(true);
      response = await addService(`investor_invoice_payment/${id}`, data);
    } else {
      setLoading(true);
      response = await addService(`add_investor_invoice_payment`, data);
    }
    if (response?.status === 200) {
      setLoading(false);
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/paymentListView');
    } else if (response?.status === 500) {
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    } else {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4">{id ? 'EDIT' : 'ADD'} Payment</h2>

      <Form>
        <Row>
          <Col xs={12} xl={9}>
            <Row as="form" className="g-3 mb-6">
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingSelectCity" label="Investor">
                  <Form.Select
                    name="investor_id"
                    value={fields['investor_id']}
                    onChange={handleSelect}
                  >
                    <option>Select Investor</option>
                    {supplierList.length > 0 &&
                      supplierList.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.investor_name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingSelectCity" label="Invoice">
                  <Form.Select
                    name="invoice_id"
                    value={fields['invoice_id']}
                    onChange={handleSelect}
                  >
                    <option>Select Invoice</option>
                    {investorInvoice.length > 0 &&
                      investorInvoice.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.invoice_no}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Amount">
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    value={fields['amount']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingSelectCity"
                  label="Payment mode"
                >
                  <Form.Select
                    name="mode_of_payment"
                    value={fields['mode_of_payment']}
                    onChange={handleSelect}
                  >
                    <option>Select Status</option>
                    <option value="1">Cash</option>
                    <option value="2">Online</option>
                    <option value="3">Cheque</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              {/* <Col sm={6} md={4}>
                <DatePicker
                  //   value={formData['invoice_date']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  //   onChange={([date]) => {
                  //     handleDateChanges(date);
                  //   }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          Invoice Date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
              </Col> */}
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Receiver Name"
                >
                  <Form.Control
                    type="text"
                    placeholder="Receiver Name"
                    name="receiver_name"
                    value={fields['receiver_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Transaction Id"
                >
                  <Form.Control
                    placeholder="Transaction Id"
                    name="transaction_no"
                    value={fields['transaction_no']}
                    onChange={handleChange}
                    maxLength={20}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} className="gy-6">
                <div className="d-flex justify-content-end gap-3">
                  <Link to="/paymentListView">
                    <Button variant="phoenix-primary" className="px-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-5 px-sm-15"
                    // type="submit"
                    onClick={handleSave}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddPayment;
