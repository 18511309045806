/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type RemoveAction = () => void;
type AddPayment = () => void;
type PaymentHistory = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  removeAction?: RemoveAction;
  addPayment?: AddPayment;
  paymentHistory?: PaymentHistory;
}

const InvestorInvDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  removeAction = () => {},
  addPayment = () => {},
  paymentHistory = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item eventKey="2" className="text-info" onClick={addPayment}>
        Add Payment
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={paymentHistory}
      >
        Payment History
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item>
    </>
  );
};

export default InvestorInvDropdownItems;
