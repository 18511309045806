import { faPaperclip, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import {
  handleKeyDown,
  imageURL,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';
interface Common {
  id: number;
  name: string;
}

interface Field {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  mobile_no: string;
  aadhar_no: string;
  license_no: string;
  country: string;
  state: string;
  city: string;
  address: string;
  error?: string;
}

const CreateContractors: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [image, setImage] = useState<any>(null);
  const [imagePre, setImagePre] = useState<File | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setImagePre(null);
    }
  };

  const [fields, setFields] = useState<Field>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile_no: '',
    aadhar_no: '',
    license_no: '',
    country: '101',
    state: '',
    city: '',
    address: ''
  });

  const clearImage = (event: any) => {
    event.preventDefault();
    setImagePre(null);
    setImage(null);
  };

  useEffect(() => {
    if (id) {
      fetchContractorDetails(id);
    }
  }, [id]);

  const fetchContractorDetails = async (id: any) => {
    setImage(null);
    const response = await getService(`contractor/${id}`);
    if (response.status === 200) {
      setImagePre(response.data[0].profile_image);
      setFields(prevFields => ({
        ...prevFields,
        first_name: response.data[0].first_name,
        last_name: response.data[0].last_name,
        email: response.data[0].email,
        // password: response.data.,
        mobile_no: response.data[0].mobile_no,
        aadhar_no: response.data[0].aadhar_no,
        license_no: response.data[0].license_no,
        country: response.data[0].country,
        state: response.data[0].state,
        city: response.data[0].city,
        address: response.data[0].address
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  const getState = async () => {
    const response = await getService(`state-by-country/${101}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('first_name', fields['first_name']);
    formData.append('last_name', fields['last_name']);
    formData.append('email', fields['email']);
    formData.append('password', fields['password']);
    formData.append('mobile_no', fields['mobile_no']);
    formData.append('aadhar_no', fields['aadhar_no']);
    formData.append('license_no', fields['license_no']);
    formData.append('country', fields['country']);
    formData.append('state', fields['state']);
    formData.append('city', fields['city']);
    formData.append('address', fields['address']);

    if (imagePre) {
      formData.append('profile_image', imagePre);
    } else {
      formData.append('profile_image', image);
    }
    let response: any = {};
    if (id) {
      response = await addService(`contractor/${id}`, formData);
    } else {
      response = await addService(`contractor`, formData);
    }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/contractors');
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };
  return (
    <div>
      <h3 className="mb-4">{id ? 'EDIT' : 'ADD'} CONTRACTOR</h3>
      <Col sm={6} md={2}>
        <div className="d-flex flex-column align-items-center border rounded-circle p-2 cursor-pointer overflow-hidden mb-2">
          <label
            className={
              image
                ? 'd-none'
                : imagePre
                ? 'd-none'
                : 'text-body fs-9 cursor-pointer'
            }
            htmlFor="attachments"
          >
            <FontAwesomeIcon icon={faPaperclip} className="me-1" />
            <span className="ml-2">Upload Image</span>
          </label>

          <Form.Control
            className="d-none"
            type="file"
            id="attachments"
            onChange={handleImageChange}
          />
          {id && imagePre ? (
            <>
              <img
                src={`${imageURL}/public/${imagePre}`}
                alt="Profile Image"
                style={{ maxWidth: '100%', borderRadius: '50%' }}
              />
            </>
          ) : (
            image && (
              <img
                src={URL.createObjectURL(image)}
                alt="Profile Image"
                style={{ maxWidth: '100%', borderRadius: '50%' }}
              />
            )
          )}
        </div>
        {(imagePre || image) && (
          <Col sm={8} md={8}>
            <span
              onClick={clearImage}
              style={{
                position: 'relative',
                bottom: 0,
                left: '80px',
                cursor: 'pointer'
              }}
            >
              <p>Remove Profile </p>
            </span>
          </Col>
        )}
      </Col>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="First Name">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={fields['first_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Last Name">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={fields['last_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Mobile Numbar"
              >
                <Form.Control
                  type="text"
                  placeholder="Mobile Numbar"
                  name="mobile_no"
                  value={fields['mobile_no']}
                  onChange={handleChange}
                  maxLength={10}
                  onKeyDown={handleKeyDown}
                  pattern="[0-9]*"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Country">
                <Form.Control
                  type="text"
                  placeholder="India"
                  name="India"
                  value={'India'}
                  // onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="State">
                <Form.Select
                  name="state"
                  value={fields['state']}
                  onChange={handleSelect}
                >
                  <option>Select State</option>
                  {state.length > 0 &&
                    state.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="City">
                <Form.Select
                  name="city"
                  value={fields['city']}
                  onChange={handleSelect}
                >
                  <option>Select City</option>
                  {city.length > 0 &&
                    city.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Address">
                <Form.Control
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={fields['address']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Email ID">
                <Form.Control
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  value={fields['email']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Password">
                <Form.Control
                  type="text"
                  placeholder="Password"
                  name="password"
                  value={fields['password']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Licence">
                <Form.Control
                  type="text"
                  placeholder="Licence"
                  name="license_no"
                  value={fields['license_no']}
                  onChange={handleChange}
                  style={{ textTransform: 'uppercase' }}
                  maxLength={20}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Aadhar Number"
              >
                <Form.Control
                  type="text"
                  placeholder="Aadhar Number"
                  name="aadhar_no"
                  value={fields['aadhar_no']}
                  onChange={handleChange}
                  maxLength={12}
                  onKeyDown={handleKeyDown}
                  pattern="[0-9]*"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="Status">
                <Form.Select>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/contractors">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateContractors;

// import React from 'react';
// import { Row, Col } from 'react-bootstrap';

// const CreateContractors = () => {
//   const data = [
//     {
//       id: 1,
//       user_id: 32,
//       first_name: 'Raj',
//       last_name: 'Kumar',
//       mobile_no: '9857412360',
//       email: 'rajkumar123@gmail.com',
//       aadhar_no: 123456789123,
//       identity_doc: null,
//       profile_image: 'uploads/profileimage/1717749521.jpg',
//       license_no: 'DL12345678',
//       digital_sign: null,
//       country: 101,
//       state: 21,
//       city: 2229,
//       address: 'Indore',
//       created_at: '2024-06-07T08:38:41.000000Z',
//       updated_at: '2024-06-07T08:38:41.000000Z',
//       created_by: 1,
//       updated_by: null,
//       site_count: 0,
//       countrydata: {
//         id: 101,
//         shortname: 'IN',
//         name: 'India',
//         phonecode: 91,
//         created_at: null,
//         updated_at: null
//       },
//       statedata: {
//         id: 21,
//         name: 'Madhya Pradesh',
//         country_id: 101,
//         created_at: null,
//         updated_at: null
//       },
//       citydata: {
//         id: 2229,
//         name: 'Indore',
//         state_id: 21,
//         created_at: null,
//         updated_at: null
//       }
//     }
//   ];

//   const contractor = data[0]; // Assuming there is only one contractor in the data array

//   return (
//     <div className="container-fluid">
//       <Row>
//         <Col md={12}>
//           <h1>Contractor Profile</h1>
//           <div className="text-center">
//             <img
//               src={`https://srsc.avark.in/public/${contractor.profile_image}`}
//               alt={`${contractor.first_name} ${contractor.last_name}`}
//               style={{ width: '200px', height: '200px' }}
//               className="border rounded-circle"
//             />
//             <h3 className="mt-5">
//               {contractor.first_name} {contractor.last_name}
//             </h3>
//             <h3 className="mt-5">
//               {' '}
//               <strong>Licence No. </strong>
//               {contractor.license_no}
//             </h3>
//           </div>
//           <div className="text-center">
//             <p>
//               <strong>Email:</strong> {contractor.email}
//               <br />
//               <strong>Mobile:</strong> {contractor.mobile_no}
//               <br />
//               <strong>Address:</strong> {contractor.address},{' '}
//               {contractor.citydata.name}, {contractor.statedata.name},{' '}
//               {contractor.countrydata.name}
//             </p>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default CreateContractors;
