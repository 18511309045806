import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileCircleExclamation,
  faFileCode,
  faFileLines,
  faFilePdf,
  faFileZipper,
  faImage,
  faMusic
} from '@fortawesome/free-solid-svg-icons';
import { baseLocalURL } from 'config';
import { toast } from 'react-toastify';
import ts, { transpile } from 'typescript';

export const imageURL = baseLocalURL;
// export const imageURL = 'https://srsc.avark.in';

export const getSystemTheme = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const getItemFromStore = (
  key: string,
  defaultValue?: string | boolean,
  store = localStorage
) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key) as string);
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const cleanText = (string: string) =>
  (string.charAt(0).toUpperCase() + string.slice(1))
    .replace(/-/g, ' ')
    .replace('_and_', '&');

export const setItemToStore = (
  key: string,
  payload: string,
  store = localStorage
) => store.setItem(key, payload);

export const capitalize = (string: string) =>
  (string.charAt(0).toUpperCase() + string.slice(1))
    .replace(/-/g, ' ')
    .replace('_and_', '&');

export const snakeCase = (string: string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

export const getColor = (name: string) => {
  const dom = document.documentElement;
  return getComputedStyle(dom).getPropertyValue(`--phoenix-${name}`).trim();
};

/* get Dates between */
export const getDates = (
  startDate: Date,
  endDate: Date,
  interval: number = 1000 * 60 * 60 * 24
): Date[] => {
  const duration = +endDate - +startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

export const getPastDates = (
  duration: 'week' | 'month' | 'year' | number
): Date[] => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

export const currencyFormat = (
  amount: number,
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options
  }).format(amount);
};

export const getNumbersInRange = (startAt: number, endAt: number) => {
  return [...Array(endAt + 1 - startAt).keys()].map(i => i + startAt);
};

export const numberFormat = (
  number: number,
  notation: 'standard' | 'compact' = 'standard'
) =>
  new Intl.NumberFormat('en-US', {
    notation
  }).format(number);

/* Get Random Number */
export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getFileIcon = (fileFormat: string): IconProp => {
  switch (fileFormat) {
    case 'zip':
    case 'rar':
      return faFileZipper;
    case 'bat':
      return faFileCode;
    case 'txt':
      return faFileLines;
    case 'mad':
      return faFileCircleExclamation;
    case 'wav':
      return faMusic;
    case 'pdf':
      return faFilePdf;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return faImage;
    default:
      return faFileLines;
  }
};

export const getIntegerArrayBetween = (start = 0, end: number): number[] =>
  new Array(end + 1 - start).fill(1).map((_, i) => i + start);

export const parseData = (data: string) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const rgbaColor = (color = '#fff', alpha = 0.5) =>
  `rgba(${hexToRgb(color)}, ${alpha})`;

export const transformTSCode = (
  snippet: string,
  target: ts.ScriptTarget = ts.ScriptTarget.ES2015
) =>
  transpile(snippet, {
    jsx: ts.JsxEmit.React,
    target
  });

export const getFileExtension = (fileName: string, separator = '.') =>
  fileName.split(separator).pop() || 'unknown';

export const isImageFile = (file: File) => {
  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp'
  ];
  return imageMimeTypes.includes(file.type);
};

export const convertFileToAttachment = (file: File) => ({
  name: file.name,
  size: `${(file.size / 1024).toFixed(2)} KB`,
  format: getFileExtension(file.name),
  preview: isImageFile(file) ? URL.createObjectURL(file) : undefined
});

export const getProgressColorVariant = (value: number) => {
  if (value < 21) {
    return 'warning';
  } else if (value < 41) {
    return 'info';
  } else if (value < 81) {
    return 'primary';
  } else {
    return 'success';
  }
};

export const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'urgent':
      return 'danger';
    case 'high':
      return 'warning';
    case 'medium':
      return 'success';
    case 'low':
      return 'info';
    default:
      return 'primary';
  }
};

export const invertHex = (hex: string) =>
  `#${(Number(`0x1${hex.slice(1)}`) ^ 0xffffff)
    .toString(16)
    .substring(1)
    .toUpperCase()}`;

export const getColorByBgColor = (bgColor: string) => {
  const rgba = hexToRgb(bgColor);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return 'black';
  } else {
    return 'white';
  }
};

export const jsonToFormData = (json: Record<string, any>): FormData => {
  const formData = new FormData();
  Object.keys(json).forEach(key => {
    formData.append(key, json[key]);
  });
  return formData;
};
export const formatDateForAPI = (dateString: string | Date): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getStatusText = (status: number): string => {
  switch (status) {
    case 1:
      return 'Pending';
    case 2:
      return 'Blocked';
    case 3:
      return 'InProgress';
    case 4:
      return 'QA';
    case 5:
      return 'Completed';
    default:
      return '';
  }
};

export const calculateGST = (amount: number, gstPercentage: number): string => {
  // Convert the GST percentage to a decimal
  const gstDecimal: number = gstPercentage / 100;
  // Calculate the GST amount
  const gstAmount: number = amount * gstDecimal;
  // Add the GST amount to the original amount
  const totalAmount: number = amount + gstAmount;
  const formattedGST: string = totalAmount.toFixed(2);
  return formattedGST;
};

interface ErrorData {
  [key: string]: string[];
}

export const validationErrorFromAPI = (data: ErrorData) => {
  // Extract key-value pairs from the response and show as toast errors
  Object.entries(data).forEach(([key, value]) => {
    const message = Array.isArray(value) ? value.join(', ') : value;
    toast.error(`${key}: ${message}`);
  });
};

export const generateRandom8DigitNumber = (): number => {
  // Generate a random number in the range [10000000, 99999999]
  return Math.floor(10000000 + Math.random() * 90000000);
};

const ones = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine'
];
const teens = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen'
];
const tens = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety'
];

export const convertNumberToWords = (num: number): string => {
  if (num === 0) return 'zero';

  let words = '';

  if (num < 0) {
    words += 'minus ';
    num = Math.abs(num);
  }

  if (num >= 10000000) {
    words += convertNumberToWords(Math.floor(num / 10000000)) + ' crore ';
    num %= 10000000;
  }

  if (num >= 100000) {
    words += convertNumberToWords(Math.floor(num / 100000)) + ' lakh ';
    num %= 100000;
  }

  if (num >= 1000) {
    const thousands = Math.floor(num / 1000);
    words += convertNumberToWords(thousands) + ' thousand ';
    num %= 1000;
  }

  if (num >= 100) {
    const hundreds = Math.floor(num / 100);
    words += ones[hundreds] + ' hundred ';
    num %= 100;
  }

  if (num >= 20) {
    const tensDigit = Math.floor(num / 10);
    words += tens[tensDigit] + ' ';
    num %= 10;
  } else if (num >= 10) {
    words += teens[num - 10] + ' ';
    num = 0;
  }

  if (num > 0) {
    words += ones[num];
  }

  return words.trim();
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0'); // Pad single-digit days with a leading zero
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const dateFormat = (dateString: string): string => {
  const [year, month, day] = dateString.split('-'); // Split the date string into year, month, and day
  return `${day}-${month}-${year}`; // Reorder and format the date components
};

export const openInNewTab = (url: any) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.opener = null;
  } else {
    console.error(
      'Failed to open PDF in a new tab. Please check your popup blocker settings.'
    );
  }
};

export const formatNumber = (number: number): string => {
  // Format the number to ensure it has a decimal point
  const formatted = number.toLocaleString('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Remove the last two digits after the decimal point
  const [integerPart, decimalPart] = formatted.split('.');

  // If there is no decimal part, just return the integer part
  if (!decimalPart) {
    return integerPart;
  }

  // Remove the last two digits of the decimal part
  const trimmedDecimalPart = decimalPart.slice(0, -2);

  // Return the formatted number with trimmed decimal part
  return integerPart + (trimmedDecimalPart ? `.${trimmedDecimalPart}` : '');
};

export const convertJsonToCsv = (data: { [key: string]: any }[]): string => {
  if (data.length === 0) return '';

  // Extract headers from the first object
  const headers = Object.keys(data[0]);

  // Convert data to CSV rows
  const csvRows = [
    headers.join(','), // Header row
    ...data.map(row =>
      headers
        .map(header => JSON.stringify(row[header], (_, value) => value ?? ''))
        .join(',')
    )
  ];

  return csvRows.join('\n');
};

export const handleKeyDown: React.KeyboardEventHandler<
  HTMLInputElement
> = e => {
  // Allow only numbers and prevent text input
  if (
    !/[0-9]/.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Tab' // Allow Tab key
  ) {
    e.preventDefault();
  }
};
