// import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import RevealDropdown, {
//   RevealDropdownTrigger
// } from 'components/base/RevealDropdown';
// import ActionDropdownItems from 'components/common/ActionDropdownItems';
// import ConfirmationModal from 'components/modals/ConfirmationModal';
// // import PageBreadcrumb from 'components/common/PageBreadcrumb';
// import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
// import ContractorListTable, {
//   contractorListTableColumns
// } from 'components/tables/ContractorListTable';
// // import { defaultBreadcrumbItems } from 'data/commonData';
// // import { projects } from 'data/project-management/projects';
// import { materialListDetail } from 'data/project-management/materialListDetail';
// import { validationErrorFromAPI } from 'helpers/utils';
// import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableProvider from 'providers/AdvanceTableProvider';
// import { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { deleteService, getService } from 'services/services';

// interface Project {
//   id: number;
//   name: string;
//   unit: number;
// }

// const MaterialListView = () => {
//   const navigate = useNavigate();
//   const [materialList, setMaterialList] = useState([]);
//   const [materialId, setMaterialId] = useState('');
//   const [showAlert, setShowAlert] = useState(false);

//   const confirmAlert = (id: any) => {
//     setShowAlert(true);
//     setMaterialId(id);
//   };
//   const removeApi = async () => {
//     const response = await deleteService(`material/${materialId}`);
//     if (response?.status === 200) {
//       fetch();
//       setShowAlert(false);
//       setMaterialId('');
//     } else if (response?.status === 500) {
//       validationErrorFromAPI(response.data);
//     }
//   };

//   const materialListTableColumns: ColumnDef<Project>[] = [
//     {
//       accessorKey: 'name',
//       header: 'Material Name',
//       cell: ({ row: { original } }) => {
//         const { name } = original;
//         return (
//           <Link to="#!" className="text-decoration-none fw-bold fs-8">
//             {name}
//           </Link>
//         );
//       },
//       meta: {
//         cellProps: { className: 'white-space-nowrap py-4' },
//         headerProps: { style: { width: '30%' } }
//       }
//     },
//     {
//       header: 'Unit',
//       accessorKey: 'unitdata.unit_title',
//       meta: {
//         cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
//         headerProps: { style: { width: '10%' }, className: 'ps-3' }
//       }
//     },
//     {
//       id: 'action',
//       cell: ({ row: { original } }) => {
//         const { id } = original;
//         return (
//           <RevealDropdownTrigger>
//             <RevealDropdown>
//               <ActionDropdownItems
//                 editAction={() => navigate(`/material/create-new/${id}`)}
//                 removeAction={() => confirmAlert(id)}
//               />
//             </RevealDropdown>
//           </RevealDropdownTrigger>
//         );
//       },
//       meta: {
//         headerProps: { style: { width: '10%' }, className: 'text-end' },
//         cellProps: { className: 'text-end' }
//       }
//     }
//   ];

//   const table = useAdvanceTable({
//     data: materialList,
//     columns: materialListTableColumns,
//     pageSize: 6,
//     pagination: true,
//     sortable: true
//   });

//   const fetch = async () => {
//     const response = await getService('material');
//     if (response.status === 200) {
//       setMaterialList(response.data);
//       // return <Redirect to="/" />;
//     } else if (response?.status === 500) {
//       validationErrorFromAPI(response.data);
//     }
//   };
//   useEffect(() => {
//     fetch();
//   }, []);

//   return (
//     <div>
//       {showAlert && (
//         <ConfirmationModal
//           name={'Material'}
//           show={showAlert}
//           handleClose={() => setShowAlert(false)}
//           handleAction={() => removeApi()}
//         />
//       )}
//       <AdvanceTableProvider {...table}>
//         <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
//           <h2 className="mb-0">
//             <span className="me-3">Material List</span>{' '}
//             <span className="fw-normal text-body-tertiary">
//               ({materialList.length})
//             </span>
//           </h2>
//           <Link className="btn btn-primary px-5" to="/material/create-new">
//             <FontAwesomeIcon icon={faPlus} className="me-2" />
//             Add New Material
//           </Link>
//         </div>
//         <ProjectsTopSection activeView="list" />
//         <MaterialListTable />
//       </AdvanceTableProvider>
//     </div>
//   );
// };

// export const MaterialListTable = () => {
//   return (
//     <div className="border-bottom border-translucent">
//       <AdvanceTable
//         tableProps={{
//           className: 'phoenix-table border-top border-translucent fs-9'
//         }}
//       />
//       <AdvanceTableFooter pagination className="py-3" />
//     </div>
//   );
// };
// export default MaterialListView;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { imageURL, openInNewTab, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import { baseLocalURL } from 'config';
import Loader from 'helpers/Loader';

interface Material {
  id: number;
  name: string;
  image: any;
  unitdata: {
    unit_title: string;
  };
}

const MaterialListView = () => {
  const navigate = useNavigate();
  const [materialList, setMaterialList] = useState([]);
  const [materialId, setMaterialId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setMaterialId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`material/${materialId}`);
    if (response?.status === 200) {
      fetch();
      setShowAlert(false);
      setMaterialId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Material>[] = [
    {
      accessorKey: 'name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { name, image } = original;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Link
              to="#"
              onClick={() => openInNewTab(`${baseLocalURL}/public/${image}`)}
            >
              <Avatar src={`${baseLocalURL}/public/${image}`} size="m" />
            </Link>
            <p
              style={{
                marginBottom: 0,
                marginLeft: '0.75rem'
              }}
            >
              {name}
            </p>
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '40%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: {
          style: { width: '50%', minWidth: '200px' }
        },
        cellProps: {
          style: { width: '50%', minWidth: '200px' }
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => navigate(`/material/create-new/${id}`)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: materialList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const fetch = async () => {
    setLoading(true);
    const response = await getService('material');
    if (response.status === 200) {
      setLoading(false);
      setMaterialList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Material List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Link
                  className="btn btn-primary px-5"
                  to="/material/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add New Material
                </Link>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MaterialListView;
