/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type DamageAction = () => void;
type EditAction = () => void;
type ChangeAction = () => void;

interface ActionDropdownItemsProps {
  damageAction?: DamageAction; // Make the prop optional
  editAction?: EditAction;
  changeAction?: ChangeAction;
}

const InvDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  damageAction = () => {},
  editAction = () => {},
  changeAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" className="text-info" onClick={damageAction}>
        Convert into damage
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        // className="text-danger"
        onClick={editAction}
      >
        Edit Receive Qty
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        // className="text-danger"
        onClick={changeAction}
      >
        Change GST State
      </Dropdown.Item>
    </>
  );
};

export default InvDropdownItems;
