import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { faPrint, faRemove } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';
import { dateFormat, formatDate, formatDateForAPI } from 'helpers/utils';
import DatePicker from 'components/base/DatePicker';
import JsonToCsvButton from 'components/JsonToCsvButton';
import Loader from 'helpers/Loader';

interface Project {
  id: number;
  invoice_no: number;
  invoice_date: string;
  invoice_amt: string;
  gst_amt: string;
  balance_amt: string;
  paid_amt: string;
  supplier: {
    supplier_name: string;
  };
}

interface DateTo {
  toDate: any;
  fromDate: any;
}

interface Field {
  site_id: number;
  expense_id: string;
  amount: string;
  paid_amount: string;
  comments: string;
  mode_of_payment: string;
  expenses_date: any;
  status: any;
  bill_doc: any;
}

const GstReportTable = () => {
  const [gstList, setGstList] = useState([]);
  const [balanceAmt, setBalanceAmt] = useState<number>(0);
  const [paidAmt, setPaidAmt] = useState<number>(0);
  const [gstAmt, setGstAmt] = useState<number>(0);
  const [invoiceAmt, setInvoiceAmt] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const filteredData = gstList.map((data: any) => ({
    invoice_no: data.invoice_no,
    invoice_date: data.invoice_date,
    invoice_amt: data.invoice_amt,
    gst_amt: data.gst_amt,
    paid_amt: data.paid_amt,
    balance_amt: data.balance_amt,
    supplier: data.supplier.supplier_name,
    mobile_no: data.supplier.mobile_no,
    gst_no: data.supplier.gst_no,
    pancard_no: data.supplier.pancard_no,
    address: data.supplier.address,
    bank_name: data.supplier.bank_name,
    ifsc_code: data.supplier.ifsc_code,
    account_no: data.supplier.account_no
  }));

  const [fields, setFields] = useState<DateTo>({
    toDate: '',
    fromDate: ''
  });

  const toDate = formatDateForAPI(fields.toDate);
  const fromDate = formatDateForAPI(fields.fromDate);

  const grandTotal = (data: any) => {
    if (data != 'avark') {
      // Initialize totals
      const totals = data.reduce(
        (
          accumulator: {
            invoiceAmount: number;
            gstAmount: number;
            paidAmount: number;
            balanceAmount: number;
          },
          item: any
        ) => {
          return {
            invoiceAmount:
              accumulator.invoiceAmount + parseFloat(item.invoice_amt || '0'),
            gstAmount: accumulator.gstAmount + parseFloat(item.gst_amt || '0'),
            paidAmount:
              accumulator.paidAmount + parseFloat(item.paid_amt || '0'),
            balanceAmount:
              accumulator.balanceAmount + parseFloat(item.balance_amt || '0')
          };
        },
        { invoiceAmount: 0, gstAmount: 0, paidAmount: 0, balanceAmount: 0 }
      );

      setInvoiceAmt(totals.invoiceAmount.toFixed(2));
      setGstAmt(totals.gstAmount.toFixed(2));
      setPaidAmt(totals.paidAmount.toFixed(2));
      setBalanceAmt(totals.balanceAmount.toFixed(2));
    } else {
      setInvoiceAmt(0.0);
      setGstAmt(0.0);
      setPaidAmt(0.0);
      setBalanceAmt(0.0);
    }
  };

  const handleGst = async () => {
    const response = await getService(
      `supplier_invoice_gst_list/${toDate}/${fromDate}`
    );
    if (response.status === 200) {
      setGstList(response.data);
      grandTotal(response.data);
    } else {
      setGstList([]);
      grandTotal('avark');
    }
  };

  const clearToDate = (event: any) => {
    event.preventDefault();
    setFields({
      ...fields,
      toDate: ''
    });
    fetch();
  };
  const clearFromDate = (event: any) => {
    event.preventDefault();
    setFields({
      ...fields,
      fromDate: ''
    });
    fetch();
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await getService('supplier_invoice_gst_list');
      if (response.status === 200) {
        setLoading(false);
        setGstList(response.data);
        grandTotal(response.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'invoice_no',
      header: 'Invoice no',
      cell: ({ row: { original } }) => {
        const { invoice_no } = original;
        return (
          // <Link to="#!" className="d-flex align-items-center text-body">
          <Link to="#!" className="">
            {/* <p className="mb-0 ms-3 text-body-emphasis fw-semibold"> */}
            <p className="mb-0 fw-bold">{invoice_no}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Invoice date',
      cell: ({ row: { original } }) => {
        const { invoice_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {invoice_date ? dateFormat(invoice_date) : ''}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '20%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'supplier.supplier_name',
      header: 'Supplier name',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'invoice_amt',
      header: 'Invoice amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'gst_amt',
      header: 'GST Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'paid_amt',
      header: 'Paid amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    },
    {
      accessorKey: 'balance_amt',
      header: 'Balance amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '20%' } }
      }
    }
  ];

  const table = useAdvanceTable({
    data: gstList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <div className="mb-9">
        <h4 className="mb-5">GST Report Table</h4>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search...."
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <DatePicker
                  value={fields['toDate']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  onChange={([date]) => {
                    setFields({
                      ...fields,
                      toDate: date
                    });
                  }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          To date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
                {fields.toDate && (
                  <Col>
                    <span
                      onClick={clearToDate}
                      style={{
                        position: 'relative',
                        bottom: 34,
                        left: 180,
                        cursor: 'pointer'
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} className="me-sm-2" />
                    </span>
                  </Col>
                )}
              </Col>

              <Col xs="auto">
                <DatePicker
                  value={fields['fromDate']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  onChange={([date]) => {
                    setFields({
                      ...fields,
                      fromDate: date
                    });
                  }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          From date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
                {fields.fromDate && (
                  <Col xs="auto">
                    <span
                      onClick={clearFromDate}
                      style={{
                        position: 'relative',
                        bottom: 34,
                        left: 180,
                        cursor: 'pointer'
                      }}
                    >
                      <FontAwesomeIcon icon={faRemove} className="me-sm-2" />
                    </span>
                  </Col>
                )}
              </Col>
              {fields.fromDate && fields.toDate && (
                <Col xs="auto">
                  <Button
                    onClick={handleGst}
                    variant="primary"
                    className="px-5"
                  >
                    Apply
                  </Button>
                </Col>
              )}
              {gstList.length > 0 && (
                <Col xs="auto" className="py-2">
                  <Row className="text-center">
                    <Col>
                      <h6>Invoice AMT</h6>
                      <p className="fw-bold">{invoiceAmt}</p>
                    </Col>
                    <Col>
                      <h6>GST AMT</h6>
                      <p className="fw-bold">{gstAmt}</p>
                    </Col>
                    <Col>
                      <h6>Paid AMT</h6>
                      <p className="fw-bold">{paidAmt}</p>
                    </Col>
                    <Col>
                      <h6>Balance AMT</h6>
                      <p className="fw-bold">{balanceAmt}</p>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>

              <Col xs="auto">
                <JsonToCsvButton
                  data={filteredData}
                  filename="GST Report.csv"
                />
                {/* <Button
                  onClick={() => console.log('clicked')}
                  variant="primary"
                  className="px-5"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" />
                  Print
                </Button> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default GstReportTable;
