import { faPaperclip, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import {
  handleKeyDown,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface Field {
  business_name: string;
  investor_name: string;
  mobile_no: string;
  gst_no: string;
  pancard_no: string;
  country: string;
  state: string;
  city: string;
  address: string;
  bank_name: string;
  account_no: string;
  ifsc_code: string;
  error?: string;
}

const CreateInvestor: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [image, setImage] = useState<File | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const [fields, setFields] = useState<Field>({
    business_name: '',
    investor_name: '',
    mobile_no: '',
    gst_no: '',
    pancard_no: '',
    country: '101',
    state: '',
    city: '',
    address: '',
    bank_name: '',
    account_no: '',
    ifsc_code: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  const getState = async () => {
    const response = await getService(`state-by-country/${101}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSupplierDetails(id);
    }
  }, [id]);

  const fetchSupplierDetails = async (id: any) => {
    const response = await getService(`investor/${id}`);
    if (response.status === 200) {
      setFields(prevFields => ({
        ...prevFields,
        business_name: response.data.business_name,
        investor_name: response.data.investor_name,
        mobile_no: response.data.mobile_no,
        gst_no: response.data.gst_no,
        pancard_no: response.data.pancard_no,
        country: response.data.country,
        state: response.data.state,
        city: response.data.city,
        address: response.data.address,
        bank_name: response.data.bank_name,
        account_no: response.data.account_no,
        ifsc_code: response.data.ifsc_code,
        status: 1
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    if (id) {
      response = await addService(`investor/${id}`, data);
    } else {
      response = await addService(`investor`, data);
    }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/investorListView');
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4">{id ? 'Edit' : 'Add'} Investor</h2>

      <Form>
        <Row>
          <Col xs={12} xl={9}>
            <Row as="form" className="g-3 mb-6">
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Business Name"
                >
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    name="business_name"
                    value={fields['business_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Investor Name"
                >
                  <Form.Control
                    type="text"
                    placeholder="investor Name"
                    name="investor_name"
                    value={fields['investor_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Mobile Numbar"
                >
                  <Form.Control
                    placeholder="Mobile Numbar"
                    name="mobile_no"
                    value={fields['mobile_no']}
                    onChange={handleChange}
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                    pattern="[0-9]*"
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Country">
                  <Form.Control
                    type="text"
                    placeholder="India"
                    name="India"
                    value={'India'}
                    // onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingSelectState" label="State">
                  <Form.Select
                    name="state"
                    value={fields['state']}
                    onChange={handleSelect}
                  >
                    <option>Select State</option>
                    {state.length > 0 &&
                      state.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingSelectCity" label="City">
                  <Form.Select
                    name="city"
                    value={fields['city']}
                    onChange={handleSelect}
                  >
                    <option>Select City</option>
                    {city.length > 0 &&
                      city.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Address">
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={fields['address']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="GST No.">
                  <Form.Control
                    type="text"
                    placeholder="GST No."
                    name="gst_no"
                    value={fields['gst_no']}
                    onChange={handleChange}
                    maxLength={15}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="PAN CARD">
                  <Form.Control
                    placeholder="PAN CARD"
                    name="pancard_no"
                    value={fields['pancard_no']}
                    onChange={handleChange}
                    maxLength={10}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Bank Name">
                  <Form.Control
                    placeholder="Bank Name"
                    name="bank_name"
                    value={fields['bank_name']}
                    onChange={handleChange}
                    maxLength={40}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>{' '}
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Account No."
                >
                  <Form.Control
                    placeholder="Account No."
                    name="account_no"
                    value={fields['account_no']}
                    onChange={handleChange}
                    maxLength={18}
                    onKeyDown={handleKeyDown}
                    pattern="[0-9]*"
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="IFSC Code">
                  <Form.Control
                    placeholder="IFSC Code"
                    name="ifsc_code"
                    value={fields['ifsc_code']}
                    onChange={handleChange}
                    maxLength={11}
                    style={{ textTransform: 'uppercase' }}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={4} md={2}>
                {/* <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                  <Button className="p-2 d-flex align-items-center">
                    <label
                      className="text-body fs-9 cursor-pointer"
                      htmlFor="attachments"
                    >
                      <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                      <span className="ml-2">Upload Document</span>
                    </label>
                  </Button>
                  <Form.Control
                    className="d-none"
                    type="file"
                    id="attachments"
                    onChange={handleImageChange}
                  />
                </div> */}
                {/* {image && (
                  <div className="mt-3 border rounded">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Uploaded"
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                )} */}
              </Col>
              {/* <Col sm={6} md={4}>
                <Link className="btn btn-primary px-5" to="#">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add more document
                </Link>
              </Col> */}
              <Col xs={12} className="gy-6">
                <div className="d-flex justify-content-end gap-3">
                  <Link to="/investorListView">
                    <Button variant="phoenix-primary" className="px-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-5 px-sm-15"
                    // type="submit"
                    onClick={handleSave}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateInvestor;
