import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import CardViewItem from 'components/modules/project-management/card-view/CardViewItem';
import CompletedSiteCardItem from 'components/modules/project-management/card-view/CompletedSiteCardItem';
import SiteCardViewItem from 'components/modules/project-management/card-view/SiteCardViewItem';
import { Site, projects } from 'data/project-management/projects';
import Loader from 'helpers/Loader';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

export const columns: ColumnDef<Site>[] = [
  {
    // For filtering and searching projects by status
    id: 'site_id',
    accessorFn: ({ site_id }) => site_id
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

const CompletedSiteCardView = () => {
  const [siteList, setSiteList] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // For filtering the original list
  const [filteredSiteList, setFilteredSiteList] = useState<Site[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await getService('site_completed');
      if (response.status === 200) {
        setLoading(false);
        setSiteList(response.data);
        setFilteredSiteList(response.data); // Initialize filtered list
      } else {
        setLoading(false);
      }
    };

    fetch();
  }, []);

  // Update filteredSiteList based on searchTerm
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSiteList(siteList); // Reset to original list
    } else {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const filteredItems = siteList.filter((item: any) =>
        item.site_name.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredSiteList(filteredItems); // Update filtered list
    }
  }, [searchTerm, siteList]);

  const table = useAdvanceTable<Site>({
    data: filteredSiteList,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <Row className="d-flex align-items-center mb-4">
          <Col sm={9}>
            <h2 className="mb-0">
              <span className="me-3">Completed Site</span>{' '}
              <span className="fw-normal text-body-tertiary">
                ({siteList.length})
              </span>
            </h2>
            {/* <Link className="btn btn-primary px-5" to="/site/create-new">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create New Site
            </Link> */}
          </Col>
          <Col sm={3}>
            <Form>
              <Form.Group controlId="searchForm">
                <Form.Control
                  type="text"
                  name="searchTerm"
                  placeholder="Search Sites..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        {/* <ProjectsTopSection activeView="card" /> */}

        <Row className="g-3 mb-9">
          {table
            .getRowModel()
            .rows.map(row => row.original)
            .map(site => (
              <Col xs={12} sm={6} xl={4} xxl={3} key={site.id}>
                {/* <SiteCardViewItem project={site} /> */}
                <CompletedSiteCardItem project={site} />
              </Col>
            ))}
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default CompletedSiteCardView;
