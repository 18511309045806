import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import InvoiceListDropdownItems from 'components/common/InvoiceListDropdownItems';
import Loader from 'helpers/Loader';
import DatePicker from 'components/base/DatePicker';

// interface Supplier {
//   id: number;
//   supplier_name: string;
//   business_name: string;
//   gst_no: string;
//   mobile_no: string;
//   address: string;
// }

interface InvestorInvoice {
  id: number;
  business_name: string;
  amount: string;
  mode_of_payment: string;
  receiver_name: string;
  mobile_no: string;
  gst_no: string;
  pancard_no: string;
  countrydata: {
    name: string;
  };
  statedata: {
    name: string;
  };
  citydata: {
    name: string;
  };
  address: string;
  bank_name: string;
  // Add more fields as needed

  total_invoice_count: number;
  total_amt: number;
  total_paid: number;
  total_remain: number;
}
interface Field {
  investor_id: string;
  receiver_name: string;
  invoice_id: string;
  amount: string;
  mode_of_payment: string;
  transaction_no: string;
  payment_date: any;
}

const PaymentListView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [supplierlList, setSupplierlList] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [investorName, setInvestorName] = useState<any>('');
  const [invoiceNo, setInvoiceNo] = useState<any>('');
  const [fields, setFields] = useState<Field>({
    investor_id: '',
    invoice_id: '',
    receiver_name: '',
    amount: '',
    mode_of_payment: '',
    transaction_no: '',
    payment_date: ''
  });

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setSupplierId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(
      `delete_investor_invoice_payment/${supplierId}`
    );
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShowAlert(false);
      setSupplierId('');
      fetch();
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleShow = (data: any) => {
    setShow(true);
    setSupplierId(data.id);
    setInvoiceNo(data.invoice.invoice_no);
    setInvestorName(data.investor.investor_name);
    console.log('PPP', data);
    setFields({
      investor_id: data.investor_id,
      invoice_id: data.invoice_id,
      receiver_name: data.receiver_name,
      amount: data.amount,
      mode_of_payment: data.mode_of_payment,
      transaction_no: data.transaction_no,
      payment_date: data.payment_date
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    // if (id) {
    //   setLoading(true);
    //   response = await addService(`investor_invoice_payment/${id}`, data);
    // } else {
    setLoading(true);
    response = await addService(
      `update_investor_invoice_payment/${supplierId}`,
      data
    );
    // }
    if (response?.status === 200) {
      fetch();
      setShow(false);
      setLoading(false);
      toast.success('Payment Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    } else if (response?.status === 500) {
      setShow(false);
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    } else {
      setLoading(false);
      setShow(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(
      `show_investor_invoice_payment_by_invoice_id/${id}`
    );
    if (response.status === 200) {
      setLoading(false);
      setSupplierlList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<InvestorInvoice>[] = [
    {
      accessorKey: 'invoice.invoice_no',
      header: 'Invoice No',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'payment_date',
      header: 'payment date',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mode_of_payment',
      header: 'Mode of payment',
      cell: ({ row: { original } }) => {
        const { mode_of_payment } = original;
        return (
          <p className="fw-bold text-body-emphasis">
            {mode_of_payment == '1'
              ? 'Cash'
              : mode_of_payment == '2'
              ? 'Online'
              : 'Cheque'}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'transaction_no',
      header: 'Transaction Id',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'receiver_name',
      header: 'receiver Name',
      cell: ({ row: { original } }) => {
        const { receiver_name } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {receiver_name}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                editAction={() => handleShow(original)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: supplierlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Payment'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Payment History</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link className="btn btn-primary px-5" to="/addPayment">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Payment
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Investor"
                      >
                        <Form.Control value={investorName} readOnly />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Invoice"
                      >
                        <Form.Control value={invoiceNo} readOnly />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={fields['amount']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <DatePicker
                        value={fields['payment_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            payment_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Payment Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectCity"
                        label="Payment mode"
                      >
                        <Form.Select
                          name="mode_of_payment"
                          value={fields['mode_of_payment']}
                          onChange={handleSelect}
                        >
                          <option>Select Status</option>
                          <option value="1">Cash</option>
                          <option value="2">Online</option>
                          <option value="3">Cheque</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Receiver Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Receiver Name"
                          name="receiver_name"
                          value={fields['receiver_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Transaction Id"
                      >
                        <Form.Control
                          placeholder="Transaction Id"
                          name="transaction_no"
                          value={fields['transaction_no']}
                          onChange={handleChange}
                          maxLength={20}
                          style={{ textTransform: 'uppercase' }}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PaymentListView;
