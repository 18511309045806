import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import {
  faArrowLeft,
  faDownload,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import { getService } from 'services/services';
import { useNavigate, useParams } from 'react-router-dom';
import { convertNumberToWords, formatDate } from 'helpers/utils';
import { Container } from 'react-bootstrap';
// import Loader from 'helpers/Loader';

interface Quotationdetail {
  rate: number;
  gst: string;
  gst_amt: number;
  total_amount: number;
  material: {
    name: string;
  };
}

interface Challan {
  quotationdetail?: Quotationdetail[];
  id: string;
  q_date: any;
  name: string;
  email: string;
  mobile_number: string;
  updated_at: any;
  company: {
    name: string;
    address: string;
  };
}

const Quotation = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [challanData, setChallanData] = useState<Partial<Challan>>({});
  const [loading, setLoading] = useState(true);

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // To reload the page after printing
    }
  };

  let totalQuantity = 0;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault(); // Prevent the default print dialog
        handlePrint();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(`quotation/${id}`);
    if (response.status === 200) {
      setLoading(false);
      setChallanData(response.data[0]);
    } else if (response.status === 500) {
      setLoading(false);
    }
  };

  const formatTime = (time: string) => {
    const date = new Date(time);
    const formattedTime = date.toLocaleTimeString();
    return formattedTime;
  };
  //   if (loading) return <Loader />;
  return (
    <Container
      style={{
        maxWidth: '210mm', // A4 width
        padding: '10mm', // Adjust padding as needed
        // backgroundColor: 'white', // Page background color
        boxSizing: 'border-box', // Include padding in width and height
        margin: '0 auto' // Center align on page
      }}
    >
      <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
        <h2 className="mb-0 flex-1">Quotation</h2>
        <Button variant="phoenix-secondary" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Back</span>
        </Button>
        <Button variant="phoenix-secondary" onClick={handlePrint}>
          <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Download Quotation</span>
        </Button>
        <Button variant="phoenix-secondary" onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
          <span className="d-none d-sm-inline-block">Print</span>
        </Button>
      </div>
      <div id="printChallan" ref={printRef}>
        <h3 className="text-center py-2">B K Engineering</h3>
        <p className="text-center py-2" style={{ lineHeight: '0.1' }}>
          Address: # 0013, Nemawar Road, By-Pass Square, NH-3, Indore, (M.P.)
          452001
        </p>
        <h4 className="text-center py-2">Quotation</h4>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="border" style={{ width: '50%' }}>
            <p className="text-center py-1">Quotation</p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '20%' }} className="px-2">
                <p>ID:</p>
                <p>Date:</p>
                <p>Time:</p>
              </div>
              <div style={{ width: '80%' }}>
                <p>{challanData.id}</p>
                <p>{challanData.q_date}</p>
                <p>{formatTime(challanData.updated_at)}</p>
              </div>
            </div>
          </div>
          <div className="border" style={{ width: '50%', borderWidth: 1 }}>
            <p className="text-center py-1">Client detail</p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="px-2">
                <p style={{ lineHeight: '0.5' }}>
                  {challanData?.company?.name}
                </p>
                <p style={{ lineHeight: '0.5' }}>Name: {challanData?.name}</p>
                <p style={{ lineHeight: '0.5' }}>
                  Contact No. {''}
                  {challanData?.mobile_number}
                </p>
                <p style={{ lineHeight: '0.5' }}>
                  Email ID : {challanData?.email}
                </p>
                <p style={{ lineHeight: '0.5' }}>
                  Address: {challanData?.company?.address}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Table bordered responsive className="custom-table">
          <thead>
            <tr>
              <th>SR NO.</th>
              <th>Material Name</th>
              <th className="text-center fs-9">UNIT</th>
              <th className="text-center fs-9">QTY</th>
              <th className="text-center fs-9">RATE</th>
              <th className="text-center fs-9">GST %</th>
              <th className="text-center fs-9">GST AMT</th>
              <th className="text-center fs-9">TOTAL AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {challanData?.quotationdetail &&
              challanData?.quotationdetail?.map((item: any, index) => {
                totalQuantity += item.total_amount;
                return (
                  <tr key={index}>
                    <td className="text-center py-1 fs-9">{index + 1}</td>
                    <td className="py-1">{item.material.name}</td>
                    <td className="text-center py-1 fs-9">
                      {item.unit.unit_title}
                    </td>
                    <td className="text-center py-1 fs-9">{item.quantity}</td>
                    <td className="text-center py-1 fs-9">{item.rate}</td>
                    <td className="text-center py-1 fs-9">{item.gst}</td>
                    <td className="text-center py-1 fs-9">{item.gst_amt}</td>
                    <td className="text-center py-1 fs-9">
                      {item.total_amount}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={7} className="px-2">
                Rupees in words:{' '}
                {convertNumberToWords(totalQuantity).charAt(0).toUpperCase() +
                  convertNumberToWords(totalQuantity).slice(1)}{' '}
                {'rupees only'}
              </td>
              <td className="text-center">
                <strong>{totalQuantity}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="text-end py-9 border-bottom">
          <h4>Authorized Signatory</h4>
        </div>
        <div className="print-footer text-center py-4 mb-9">
          <p className="mb-0">
            This challan is generated by ARK-Project ERP system | 2024 ©
            <a href="https://avark.in//" target="_blank" rel="noreferrer">
              Avark
            </a>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Quotation;
