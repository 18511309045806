import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { formatDate, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import { FloatingLabel } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import SupplierDropdownItems from 'components/common/SupplierDropdownItems';
import Loader from 'helpers/Loader';

interface Contractor {
  id: number;
  amount: number;
  transaction_no: string;
  mode_of_payment: number;
  created_at: string;
  invoice: {
    invoice_no: number;
    total_amount: number;
    gst_amount: number;
    invoice_date: string;
  };
  supplier: {
    supplier_name: string;
  };
}

interface InvoiceItem {
  id: number;

  supplier: {
    supplier_name: string;
  };
}

interface InvoiceNo {
  id: number;
  invoice: {
    id: number;
    invoice_no: any;
  };
}

const SupplierInvoicePayment = () => {
  const navigate = useNavigate();
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [invoiceID, setInvoiceID] = useState('');
  const [supplierID, setSupplierID] = useState('');
  const [loading, setLoading] = useState(true);

  const uniqueName = new Set<string>();

  const handleInvoiceNoChange = async (idInvoice: any) => {
    if (idInvoice) {
      setInvoiceID(idInvoice);
      const response = await getService(
        `show_invoice_payment_by_both_id/${idInvoice}/${
          supplierID ? supplierID : 0
        }`
      );
      if (response.status === 200) {
        console.log('resDone');
        setInvoiceList(response.data);
      }
    } else {
      // fetch();
      console.log('ERRR');
    }
  };

  const handleSupplierChange = async (id: any) => {
    if (id) {
      setSupplierID(id);
      const response = await getService(
        `show_invoice_payment_by_both_id/${invoiceID ? invoiceID : 0}/${id}`
      );
      if (response.status === 200) {
        setInvoiceList(response.data);
      }
    } else {
      fetch();
    }
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'invoice.invoice_no',
      header: 'Invoice No.',
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'invoice.invoice_date',
      header: 'Invoice Date',
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'supplier.supplier_name',
      header: 'Supplier Name',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mode_of_payment',
      header: 'Mode of Payment',
      cell: ({ row: { original } }) => {
        const { mode_of_payment } = original;
        return (
          <span className="fw-bold text-body-emphasis">
            {mode_of_payment === 1
              ? 'Cash'
              : mode_of_payment === 2
              ? 'Cheque'
              : mode_of_payment === 3
              ? 'Online'
              : ''}
          </span>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'created_at',
      header: 'Date of Payment',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <span className="fw-bold text-body-emphasis">
            {formatDate(created_at)}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'transaction_no',
      header: 'Transaction Id',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },

    {
      accessorKey: 'amount',
      header: 'Paid Amount',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <SupplierDropdownItems
                // editAction={() => navigate(`/contractors/create-new/${id}`)}
                paymentDetail={() => navigate(`/paymentReceipt/${id}`)}
                // viewInvoice={() => confirmAlert(id)}
                // viewInvoice={() => handleShowView(original)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: invoiceList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
    fetchSupplier();
  }, []);

  const fetchSupplier = async () => {
    setLoading(true);
    const response = await getService('supplier');
    if (response.status === 200) {
      setLoading(false);
      setSupplierList(response.data);
    } else {
      setLoading(false);
    }
  };

  const fetch = async () => {
    const response = await getService('show_all_invoice_payment');
    if (response.status === 200) {
      setInvoiceList(response.data);
      setInvoiceNo(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h3 className="mb-5">Supplier Invoice Payment</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Invoice No."
                >
                  <Form.Select
                    name="Invoice No."
                    onChange={e => handleInvoiceNoChange(e.target.value)}
                  >
                    <option value="0">Select Invoice No.</option>
                    {invoiceNo.map((item: InvoiceNo) => {
                      const { invoice_no } = item.invoice;
                      if (!uniqueName.has(invoice_no)) {
                        uniqueName.add(invoice_no);
                        return (
                          <option key={item.id} value={item.id}>
                            {invoice_no}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Supplier">
                  <Form.Select
                    name="Supplier"
                    onChange={e => handleSupplierChange(e.target.value)}
                  >
                    <option value="0">Select Supplier</option>
                    {supplierList.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.supplier_name}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Link
                  className="btn btn-primary px-5"
                  to="/contractors/create-new"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add contractor
                </Link> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default SupplierInvoicePayment;
