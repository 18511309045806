import Avatar from 'components/base/Avatar';
import Button from 'components/base/Button';
import { baseLocalURL } from 'config';
import Loader from 'helpers/Loader';
import {
  imageURL,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { FormEvent, useEffect, useState, ChangeEvent } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addService, getService } from 'services/services';

interface Company {
  id: number;
  name: string;
  industry: {
    name: string;
  };
}
interface Field {
  material_id: number | string;
  quantity?: number | string | undefined;
  per_q_rate?: number | string | undefined;
}

interface Material {
  id: number;
  name: string;
  image: any;
  unitdata: {
    unit_title: string;
  };
}

const CreateCompanyRateList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [companyId, setCompanyId] = useState<string>('');
  const [fields, setFields] = useState<Field[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  // const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = event.target;
  //   setCompanyId(value);
  //   fetchMaterial();
  // };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setCompanyId(value);
    fetchMaterial(value).catch(error => {
      console.log(error);
    });
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    // Make a shallow copy of the array of fields
    const fieldset = [...fields];
    // Make a shallow copy of the specific field object within the array
    const updatedField = { ...fieldset[index] };
    // Update the specific property within the copied field object
    // Assert that name is a valid property name of Field
    const fieldName = name as keyof Field;
    // Update the specific property within the copied field object
    updatedField[fieldName] = value;
    // // Update the array with the updated field object at the specified index
    fieldset[index] = updatedField;
    // // Set the state with the updated array
    setFields(fieldset);
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCompanyRate(id);
    }
  }, [id]);

  const fetchCompanyRate = async (id: any) => {
    const response = await getService(`companyRateList/${id}`);
    if (response.status === 200) {
      // setCompanyId(response.data.company_id);
      // setFields(prevFields => ({
      //   ...prevFields,
      //   material_id: response.data.material_id,
      //   quantity: response.data.quantity,
      //   per_q_rate: response.data.per_q_rate
      // }));
    }
  };

  const fetchCompany = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setCompanyList(response.data);
    }
  };

  const fetchMaterial = async (id: any) => {
    const response = await getService(`companyAddMaterialList/${id}`);
    // const response = await getService(`companyAddMaterialList/${}`);
    if (response.status === 200) {
      setMaterialList(response.data);
      // Initialize an empty array for the fields
      const arg: Field[] = [];
      // Iterate over the materialData and push new objects to arg
      response.data.forEach((row: { id: any }) => {
        arg.push({ material_id: row.id, quantity: '', per_q_rate: '' });
      });
      // Set the fields state with the constructed array
      setFields(arg);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    const filteredData = fields.filter(
      (item: any) => item.quantity !== '' || item.per_q_rate !== ''
    );
    const requestData: any = {
      company_id: companyId,
      company_rate: JSON.stringify(filteredData)
    };
    let response: any = {};
    if (id) {
      setLoading(true);
      response = await addService(`companyRateList/${id}`, requestData);
    } else {
      setLoading(true);
      response = await addService(`companyRateList`, requestData);
    }
    if (response?.status === 200) {
      setLoading(false);
      navigate('/companyMaterial');
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  // Handle search input change
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter materialList based on search query
  const filteredMaterialList = materialList.filter(item =>
    item.name.toLowerCase().includes(searchQuery)
  );

  if (loading) return <Loader />;

  return (
    <div>
      <h2 className="mb-4">Create Company Rate List</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={6} className="mb-4">
              <FloatingLabel controlId="floatingSelectIndustry" label="Company">
                <Form.Select
                  name="company_id"
                  value={companyId}
                  onChange={handleSelect}
                >
                  <option>Select Company</option>
                  {companyList.length > 0 &&
                    companyList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                        {'-'}
                        {item.industry.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            {materialList.length > 0 && (
              <Col sm={6} md={4} className="mb-4">
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Col>
            )}
            {materialList.length > 0 && (
              <Row className="border font-weight-bold text-center py-2">
                <Col md={1}>
                  <h6 className="align-middle text-uppercase mb-0">SR No.</h6>
                </Col>
                <Col md={5}>
                  <h6 className="align-middle text-uppercase mb-0">Items</h6>
                </Col>
                <Col md={2}>
                  <h6 className="text-uppercase mb-0">Unit</h6>
                </Col>
                <Col md={2}>
                  <h6 className="text-uppercase mb-0">Quantity</h6>
                </Col>
                <Col md={2}>
                  <h6 className="text-uppercase mb-0">PER Q RATE</h6>
                </Col>
              </Row>
            )}
            {materialList.length > 0 &&
              filteredMaterialList.map((row, index) => (
                <Row
                  key={row.id}
                  className="border align-items-center justify-content-center"
                >
                  <Col md={1}>
                    <h6>{index + 1}</h6>
                  </Col>
                  <Col md={5} className="d-flex align-items-center">
                    <Avatar
                      src={
                        row?.image ? `${baseLocalURL}/public/${row?.image}` : ''
                      }
                      size="m"
                    />{' '}
                    <h6 className="mx-2">{row.name}</h6>
                  </Col>
                  <Col md={2}>
                    <h6 className="ms-3">{row?.unitdata?.unit_title}</h6>
                  </Col>
                  <Col md={2}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Quantity"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Quantity"
                        name="quantity"
                        value={
                          fields.length > 0 ? fields[index]['quantity'] : ''
                        }
                        onChange={event => handleChange(event, index)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={2}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="PER Q RATE"
                    >
                      <Form.Control
                        type="number"
                        placeholder="PER Q RATE"
                        name="per_q_rate"
                        value={
                          fields.length > 0 ? fields[index]['per_q_rate'] : ''
                        }
                        onChange={event => handleChange(event, index)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              ))}

            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/companyMaterial">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </Form> */}
    </div>
  );
};

export default CreateCompanyRateList;
