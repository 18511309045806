import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';

interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: number;
  address: string;
  profile_image: any;
}

const OutOfStockList = () => {
  const navigate = useNavigate();
  const [outOfStockList, setOutOfStockList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      meta: {
        headerProps: {
          style: { width: '35%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'material.current_stock',
      header: 'Current Stock',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'supplier.supplier_name',
      header: 'Supplier Name',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'supplier.address',
      header: 'Supplier Address',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
              // editAction={() => navigate(`/contractors/create-new/${id}`)}
              // removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: outOfStockList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('master_inventory_out_of_stock');
    if (response.status === 200) {
      setLoading(false);
      setOutOfStockList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    } else {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h3 className="mb-5">Out Of Stock List</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              {/* <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Link
                  className="btn btn-primary px-5"
                  // to="/contractors/create-new"
                  to="#"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Generate Purchase Order
                </Link>
              </Col> */}
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default OutOfStockList;
