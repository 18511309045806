// import { ChangeEvent, useEffect, useRef, useState } from 'react';
// import Button from 'components/base/Button';
// import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
// import { addService, deleteService, getService } from 'services/services';
// import { calculateGST, validationErrorFromAPI } from 'helpers/utils';
// import { toast } from 'react-toastify';

// interface Material {
//   id: any;
//   name: string;
//   unitdata?: any;
// }

// interface Props {
//   siteId: any; // Define the prop type
// }

// interface Field {
//   id?: number | string;
//   site_id: number | string;
//   material_id?: any;
//   unit?: number | string;
//   quantity?: any;
//   rate_per_site?: any;
//   site_rate?: any;
//   gst?: any;
//   tpgst?: any;
// }

// const AddSiteInventory: React.FC<Props> = props => {
//   // eslint-disable-next-line react/prop-types
//   const { siteId } = props;
//   const materialSelectRef = useRef<HTMLSelectElement>(null);
//   const [materialList, setMaterialList] = useState<Material[]>([]);
//   const [image, setImage] = useState<File | null>(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [fields, setFields] = useState<Field[]>([
//     {
//       id: '',
//       site_id: siteId,
//       material_id: '',
//       unit: '',
//       quantity: '',
//       rate_per_site: '',
//       site_rate: '',
//       gst: '',
//       tpgst: ''
//     }
//   ]);

//   console.log('fields', fields);

//   // Handle search input change
//   const handleSearchChange = (event: any) => {
//     setSearchQuery(event.target.value.toLowerCase());
//   };

//   // Filter materialList based on search query
//   const filteredMaterialList = materialList.filter(item =>
//     item.name.toLowerCase().includes(searchQuery)
//   );

//   const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files && event.target.files[0];
//     if (file) {
//       setImage(file);
//       // Update profileimage field in fields state
//       // setFields(prevFields => ({
//       //   ...prevFields,
//       //   profileimage: file
//       // }));
//     }
//   };

//   // useEffect(() => {
//   //   fetchMaterial();
//   // }, []);

//   // const fetchMaterial = async () => {
//   //   const response = await getService(
//   //     `site_inventory_material_list_by_site_id/${siteId}`
//   //   );
//   //   if (response.status === 200) {
//   //     setMaterialList(response.data);
//   //   } else if (response?.status === 500) {
//   //     validationErrorFromAPI(response.data);
//   //   }
//   // };

//   const handleAddField = () => {
//     if (materialSelectRef && materialSelectRef.current) {
//       // Focus on the select element
//       materialSelectRef.current.focus();
//       // Set scrollTop to 0 to move the cursor to the top
//       materialSelectRef.current.scrollTop = 0;
//     }
//     const newObj: Field = {
//       id: '',
//       site_id: siteId,
//       material_id: '',
//       unit: '',
//       quantity: '',
//       rate_per_site: '',
//       site_rate: '',
//       gst: '',
//       tpgst: ''
//     };
//     // Add newObj to originalArray
//     // const originalfield: Field[] = fields.concat(newObj);
//     const originalfield: Field[] = [newObj, ...fields];
//     setFields(originalfield);
//   };

//   const handleRemoveField = async (indexToRemove: number, id?: any) => {
//     // Filter out the object to remove based on its index
//     const updatedFields: Field[] = fields.filter(
//       (_, index) => index !== indexToRemove
//     );
//     // Update the state with the filtered array
//     setFields(updatedFields);
//     if (id) {
//       await deleteService(`site_inventory/${id}`);
//     }
//   };

//   const handleSelect = (
//     event: ChangeEvent<HTMLSelectElement>,
//     index: number
//   ) => {
//     const { value } = event.target;
//     const foundMaterial = materialList.find(material => material.id == value);
//     // Make a shallow copy of the array of fields
//     const fieldset = [...fields];
//     // // Make a shallow copy of the specific field object within the array
//     const updatedField = { ...fieldset[index] };
//     // // Update the specific property within the copied field object
//     updatedField['material_id'] = value;
//     updatedField['unit'] = foundMaterial?.unitdata?.unit_title;
//     // // // Update the array with the updated field object at the specified index
//     fieldset[index] = updatedField;
//     // // // Set the state with the updated array
//     setFields(fieldset);
//   };

//   const handleChange = (
//     event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//     index: number
//   ) => {
//     const { name, value } = event.target;
//     const fieldset = [...fields];
//     const updatedField = { ...fieldset[index] };
//     const fieldName = name as keyof Field;
//     updatedField[fieldName] = value;
//     if (value) {
//       if (fieldName === 'quantity') {
//         const quantity = parseFloat(value); // Convert value to a float
//         const ratePerSite = updatedField['rate_per_site']
//           ? parseFloat(updatedField['rate_per_site'])
//           : 1; // Convert rate_per_site to a float
//         const site_rate = quantity * ratePerSite;
//         updatedField['site_rate'] = site_rate; // Perform the calculation

//         const gst = updatedField['gst'] ? parseFloat(updatedField['gst']) : 0; // Convert rate_per_site to a float
//         const totalAmount = calculateGST(site_rate, gst);
//         updatedField['tpgst'] = totalAmount; // Perform the calculation
//       }
//       if (fieldName === 'rate_per_site') {
//         const quantity = parseFloat(value); // Convert value to a float
//         const ratePerSite = updatedField['quantity']
//           ? parseFloat(updatedField['quantity'])
//           : 1; // Convert rate_per_site to a float
//         const site_rate = quantity * ratePerSite;
//         updatedField['site_rate'] = site_rate; // Perform the calculation

//         const gst = updatedField['gst'] ? parseFloat(updatedField['gst']) : 0; // Convert rate_per_site to a float
//         const totalAmount = calculateGST(site_rate, gst);
//         updatedField['tpgst'] = totalAmount; // Perform the calculation
//       }
//       if (fieldName === 'gst') {
//         const totalAmount = calculateGST(
//           updatedField['site_rate'],
//           parseFloat(value)
//         );
//         updatedField['tpgst'] = totalAmount; // Perform the calculation
//       }
//     }
//     fieldset[index] = updatedField;
//     setFields(fieldset);
//   };

//   function isRowComplete(row: any) {
//     return row.quantity !== '' && row.rate_per_site !== '';
//   }

//   // Function to check if any row is complete
//   function hasAnyCompleteRow(data: any) {
//     for (const row of data) {
//       if (isRowComplete(row)) {
//         return true; // At least one complete row found
//       }
//     }
//     // return false; // No complete rows found
//     return toast.error('Add Material', {
//       position: 'top-right',
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: 'light'
//     });
//   }

//   const handleSave = async () => {
//     if (hasAnyCompleteRow(fields)) {
//       console.log('At least one row is complete. Proceeding to call the API.');
//     }
//     // if (
//     //   fields.length > 0 &&
//     //   fields[0].quantity === '' &&
//     //   fields[0].rate_per_site === ''
//     // ) {
//     //   toast.error('Add Material', {
//     //     position: 'top-right',
//     //     autoClose: 5000,
//     //     hideProgressBar: false,
//     //     closeOnClick: true,
//     //     pauseOnHover: true,
//     //     draggable: true,
//     //     progress: undefined,
//     //     theme: 'light'
//     //   });
//     //   return;
//     // }

//     const filteredData = fields.filter(
//       (item: any) => item.quantity !== '' && item.rate_per_site !== ''
//     );
//     // console.log('filteredData', filteredData);

//     // return false;
//     const formData = new FormData();
//     formData.append('inventory', JSON.stringify(filteredData));

//     let response: any = {};
//     response = await addService(`site_inventory`, formData);
//     if (response?.status === 200) {
//       toast.success('SuccessFully Added', {
//         position: 'top-right',
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: 'light'
//       });
//       // setFields(originalfield);
//     }
//   };

//   const fetch = async () => {
//     let response: any = {};
//     response = await getService(
//       `site_inventory_material_list_by_site_id/${siteId}`
//     );
//     if (response.status === 200) {
//       // setSelectedStageList(response.data);
//       setMaterialList(response.data);
//       if (response?.data.length > 0) {
//         const originalfield: Field[] = [];
//         response.data.forEach((row: any) => {
//           const newObj: Field = {
//             id: row.id,
//             site_id: siteId,
//             material_id: row.id,
//             unit: row.unitdata?.unit_title,
//             quantity: '',
//             rate_per_site: '',
//             site_rate: '',
//             gst: '',
//             tpgst: ''
//           };
//           originalfield.push(newObj); // Push newObj into the originalfield array
//         });
//         setFields(originalfield);
//       }
//     }
//   };
//   useEffect(() => {
//     fetch();
//   }, []);

//   return (
//     <div>
//       <div className="d-flex">
//         <Col sm={6} md={4} className="mb-4">
//           <Form.Control
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={handleSearchChange}
//           />
//         </Col>
//         <Col xs={12} className="px-15">
//           <Button
//             variant="primary"
//             className="px-5 px-sm-15"
//             onClick={handleSave}
//           >
//             Save
//           </Button>
//         </Col>
//       </div>

//       <Row>
//         <Col xs={12} xl={12}>
//           <Row as="form" className="g-3 mb-6">
//             {/* <Col sm={4} md={2}>
//               <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
//                 <Button className="p-2 d-flex align-items-center">
//                   <label
//                     className="text-body fs-9 cursor-pointer"
//                     htmlFor="attachments"
//                   >
//                     <FontAwesomeIcon icon={faPaperclip} className="me-1" />
//                     <span className="ml-2">Upload Image</span>
//                   </label>
//                 </Button>
//                 <Form.Control
//                   className="d-none"
//                   type="file"
//                   id="attachments"
//                   onChange={handleImageChange}
//                 />
//               </div>
//               {image && (
//                 <div className="mt-3 border rounded position-relative">
//                   <img
//                     src={URL.createObjectURL(image)}
//                     alt="Uploaded"
//                     style={{ maxWidth: '100%' }}
//                   />
//                   <FontAwesomeIcon
//                     onClick={() => setImage(null)}
//                     icon={faRemove}
//                     className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
//                   />
//                 </div>
//               )}
//             </Col> */}
//             <div>
//               <Col xs={12} xl={12}>
//                 {fields.map((field, index) => {
//                   return (
//                     <Row as="form" className="g-3 mb-6" key={index}>
//                       <Col sm={4} md={3}>
//                         <FloatingLabel
//                           controlId="floatingSelectPrivacy"
//                           label="Material"
//                         >
//                           <Form.Select
//                             ref={materialSelectRef}
//                             value={fields[index]['material_id']}
//                             onChange={event => handleSelect(event, index)}
//                             disabled={true}
//                           >
//                             <option>Select Material</option>
//                             {materialList.length > 0 &&
//                               filteredMaterialList.map(item => (
//                                 <option key={item.id} value={item.id}>
//                                   {item.name}
//                                 </option>
//                               ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="Unit"
//                         >
//                           <Form.Control
//                             type="text"
//                             placeholder="Project title"
//                             disabled
//                             name="unit"
//                             value={fields[index]['unit']}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="Quantity"
//                         >
//                           <Form.Control
//                             type="number"
//                             placeholder="Project title"
//                             name="quantity"
//                             value={fields[index]['quantity']}
//                             onChange={event => handleChange(event, index)}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="Per Site Rate"
//                         >
//                           <Form.Control
//                             type="number"
//                             placeholder="Project title"
//                             name="rate_per_site"
//                             value={fields[index]['rate_per_site']}
//                             onChange={event => handleChange(event, index)}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="Site Rate"
//                         >
//                           <Form.Control
//                             type="number"
//                             placeholder="Project title"
//                             disabled
//                             name="site_rate"
//                             value={fields[index]['site_rate']}
//                             // onChange={event => handleChange(event, index)}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="GST %"
//                         >
//                           <Form.Control
//                             type="text"
//                             placeholder="Project title"
//                             name="gst"
//                             value={fields[index]['gst']}
//                             onChange={event => handleChange(event, index)}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col sm={2} md={1}>
//                         <FloatingLabel
//                           controlId="floatingInputGrid"
//                           label="T.P. + GST"
//                         >
//                           <Form.Control
//                             type="text"
//                             placeholder="Project title"
//                             disabled
//                             name="tpgst"
//                             value={fields[index]['tpgst']}
//                             // onChange={event => handleChange(event, index)}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       {/* <Col sm={2} md={2}>
//                         <Button
//                           variant="primary"
//                           className="px-5"
//                           // eslint-disable-next-line react/jsx-no-duplicate-props
//                           onClick={() =>
//                             index === 0
//                               ? handleAddField()
//                               : handleRemoveField(index, field.id)
//                           }
//                         >
//                           {index === 0 ? 'Enter' : 'Close'}
//                         </Button>
//                       </Col> */}
//                     </Row>
//                   );
//                 })}
//               </Col>
//             </div>
//             {/* <Col xs={12} className="gy-6">
//               <div className="d-flex justify-content-end gap-3">
//                 <Link to="/inventoryViewList">
//                   <Button variant="phoenix-primary" className="px-5">
//                     Cancel
//                   </Button>
//                 </Link>
//                 <Button variant="primary" className="px-5 px-sm-15">
//                   Add
//                 </Button>
//               </div>
//             </Col> */}
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default AddSiteInventory;
// function Number(value: string) {
//   throw new Error('Function not implemented.');
// }

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { addService, deleteService, getService } from 'services/services';
import { calculateGST, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
// import Loader from 'helpers/Loader';

interface Material {
  id: any;
  name: string;
  unitdata?: any;
}

interface Props {
  siteId: any; // Define the prop type
}

interface Field {
  id?: number | string;
  site_id: number | string;
  material_id?: any;
  unit?: number | string;
  quantity?: any;
  rate_per_site?: any;
  site_rate?: any;
  gst?: any;
  tpgst?: any;
  unit_id?: any;
}

const AddSiteInventory: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId } = props;
  const materialSelectRef = useRef<HTMLSelectElement>(null);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  // const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState<Field[]>([
    {
      id: '',
      site_id: siteId,
      material_id: '',
      unit_id: '',
      unit: '',
      quantity: '',
      rate_per_site: '',
      site_rate: '',
      gst: '',
      tpgst: ''
    }
  ]);

  // Handle search input change
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter materialList based on search query
  const filteredMaterialList = materialList.filter(item =>
    item.name.toLowerCase().includes(searchQuery)
  );

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      // Update profileimage field in fields state
      // setFields(prevFields => ({
      //   ...prevFields,
      //   profileimage: file
      // }));
    }
  };

  // useEffect(() => {
  //   fetchMaterial();
  // }, []);

  // const fetchMaterial = async () => {
  //   const response = await getService(
  //     `site_inventory_material_list_by_site_id/${siteId}`
  //   );
  //   if (response.status === 200) {
  //     setMaterialList(response.data);
  //   } else if (response?.status === 500) {
  //     validationErrorFromAPI(response.data);
  //   }
  // };

  const handleAddField = () => {
    if (materialSelectRef && materialSelectRef.current) {
      // Focus on the select element
      materialSelectRef.current.focus();
      // Set scrollTop to 0 to move the cursor to the top
      materialSelectRef.current.scrollTop = 0;
    }
    const newObj: Field = {
      id: '',
      site_id: siteId,
      material_id: '',
      unit: '',
      quantity: '',
      rate_per_site: '',
      site_rate: '',
      gst: '',
      tpgst: ''
    };
    // Add newObj to originalArray
    // const originalfield: Field[] = fields.concat(newObj);
    const originalfield: Field[] = [newObj, ...fields];
    setFields(originalfield);
  };

  const handleRemoveField = async (indexToRemove: number, id?: any) => {
    // Filter out the object to remove based on its index
    const updatedFields: Field[] = fields.filter(
      (_, index) => index !== indexToRemove
    );
    // Update the state with the filtered array
    setFields(updatedFields);
    if (id) {
      await deleteService(`site_inventory/${id}`);
    }
  };

  const handleSelect = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { value } = event.target;
    const foundMaterial = materialList.find(material => material.id == value);
    // Make a shallow copy of the array of fields
    const fieldset = [...fields];
    // // Make a shallow copy of the specific field object within the array
    const updatedField = { ...fieldset[index] };
    // // Update the specific property within the copied field object
    updatedField['material_id'] = value;
    updatedField['unit'] = foundMaterial?.unitdata?.unit_title;
    // // // Update the array with the updated field object at the specified index
    fieldset[index] = updatedField;
    // // // Set the state with the updated array
    setFields(fieldset);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    const fieldName = name as keyof Field;
    updatedField[fieldName] = value;
    if (value) {
      if (fieldName === 'quantity') {
        const quantity = parseFloat(value); // Convert value to a float
        const ratePerSite = updatedField['rate_per_site']
          ? parseFloat(updatedField['rate_per_site'])
          : 1; // Convert rate_per_site to a float
        const site_rate = quantity * ratePerSite;
        updatedField['site_rate'] = site_rate; // Perform the calculation

        const gst = updatedField['gst'] ? parseFloat(updatedField['gst']) : 0; // Convert rate_per_site to a float
        const totalAmount = calculateGST(site_rate, gst);
        updatedField['tpgst'] = totalAmount; // Perform the calculation
      }
      if (fieldName === 'rate_per_site') {
        const quantity = parseFloat(value); // Convert value to a float
        const ratePerSite = updatedField['quantity']
          ? parseFloat(updatedField['quantity'])
          : 1; // Convert rate_per_site to a float
        const site_rate = quantity * ratePerSite;
        updatedField['site_rate'] = site_rate; // Perform the calculation

        const gst = updatedField['gst'] ? parseFloat(updatedField['gst']) : 0; // Convert rate_per_site to a float
        const totalAmount = calculateGST(site_rate, gst);
        updatedField['tpgst'] = totalAmount; // Perform the calculation
      }
      if (fieldName === 'gst') {
        const totalAmount = calculateGST(
          updatedField['site_rate'],
          parseFloat(value)
        );
        updatedField['tpgst'] = totalAmount; // Perform the calculation
      }
    }
    fieldset[index] = updatedField;
    setFields(fieldset);
  };

  function isRowComplete(row: any) {
    return row.quantity !== '' && row.rate_per_site !== '';
  }

  // Function to check if any row is complete
  function hasAnyCompleteRow(data: any) {
    for (const row of data) {
      if (isRowComplete(row)) {
        return true; // At least one complete row found
      }
    }
    // return false; // No complete rows found
    return toast.error('Add Material', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light'
    });
  }

  const handleSave = async () => {
    if (hasAnyCompleteRow(fields)) {
      console.log('At least one row is complete. Proceeding to call the API.');
    }
    // if (
    //   fields.length > 0 &&
    //   fields[0].quantity === '' &&
    //   fields[0].rate_per_site === ''
    // ) {
    //   toast.error('Add Material', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'light'
    //   });
    //   return;
    // }

    const filteredData = fields.filter(
      (item: any) => item.quantity !== '' && item.rate_per_site !== ''
    );
    // console.log('filteredData', filteredData);

    // return false;
    const formData = new FormData();
    formData.append('inventory', JSON.stringify(filteredData));

    let response: any = {};
    response = await addService(`site_inventory`, formData);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      // setFields(originalfield);
    }
  };

  const fetch = async () => {
    // setLoading(true);
    let response: any = {};
    response = await getService(
      `site_inventory_material_list_by_site_id/${siteId}`
    );
    if (response.status === 200) {
      // setLoading(false);
      // setSelectedStageList(response.data);
      setMaterialList(response.data);
      if (response?.data.length > 0) {
        const originalfield: Field[] = [];
        response.data.forEach((row: any) => {
          const newObj: Field = {
            id: row.id,
            site_id: siteId,
            material_id: row.id,
            unit_id: row.unitdata?.unit_title,
            unit: row.unitdata?.id,
            quantity: '',
            rate_per_site: '',
            site_rate: '',
            gst: '',
            tpgst: ''
          };
          originalfield.push(newObj); // Push newObj into the originalfield array
        });
        setFields(originalfield);
      }
    } else if (response.status === 500) {
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  // if (loading) return <Loader />;

  return (
    <div>
      <div className="d-flex">
        <Col sm={6} md={4} className="mb-4">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
        <Col xs={12} className="px-15">
          <Button
            variant="primary"
            className="px-5 px-sm-15"
            onClick={handleSave}
          >
            Save
          </Button>
        </Col>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Row as="form" className="g-3 mb-6">
            {/* <Col sm={4} md={2}>
              <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                <Button className="p-2 d-flex align-items-center">
                  <label
                    className="text-body fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                    <span className="ml-2">Upload Image</span>
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  onChange={handleImageChange}
                />
              </div>
              {image && (
                <div className="mt-3 border rounded position-relative">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Uploaded"
                    style={{ maxWidth: '100%' }}
                  />
                  <FontAwesomeIcon
                    onClick={() => setImage(null)}
                    icon={faRemove}
                    className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                  />
                </div>
              )}
            </Col> */}
            <div>
              <Col xs={12} xl={12}>
                {fields.map((field, index) => {
                  return (
                    <Row as="form" className="g-3 mb-6" key={index}>
                      <Col sm={4} md={3}>
                        <FloatingLabel
                          controlId="floatingSelectPrivacy"
                          label="Material"
                        >
                          <Form.Select
                            ref={materialSelectRef}
                            value={fields[index]['material_id']}
                            onChange={event => handleSelect(event, index)}
                            disabled={true}
                          >
                            <option>Select Material</option>
                            {materialList.length > 0 &&
                              filteredMaterialList.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Unit"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="unit_id"
                            value={fields[index]['unit_id']}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Quantity"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="quantity"
                            value={fields[index]['quantity']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Per Site Rate"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="rate_per_site"
                            value={fields[index]['rate_per_site']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Site Rate"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            disabled
                            name="site_rate"
                            value={fields[index]['site_rate']}
                            // onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST %"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            name="gst"
                            value={fields[index]['gst']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={2} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="T.P. + GST"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="tpgst"
                            value={fields[index]['tpgst']}
                            // onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      {/* <Col sm={2} md={2}>
                        <Button
                          variant="primary"
                          className="px-5"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          onClick={() =>
                            index === 0
                              ? handleAddField()
                              : handleRemoveField(index, field.id)
                          }
                        >
                          {index === 0 ? 'Enter' : 'Close'}
                        </Button>
                      </Col> */}
                    </Row>
                  );
                })}
              </Col>
            </div>
            {/* <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/inventoryViewList">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button variant="primary" className="px-5 px-sm-15">
                  Add
                </Button>
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddSiteInventory;
function Number(value: string) {
  throw new Error('Function not implemented.');
}
