import {
  faAdd,
  faPaperclip,
  faPlus,
  faRemove,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import Loader from 'helpers/Loader';
import {
  calculateGST,
  handleKeyDown,
  validationErrorFromAPI
} from 'helpers/utils';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';

interface Supplier {
  id: number;
  supplier_name: string;
  first_name: string;
  last_name: string;
}
interface Material {
  id: any;
  name: string;
  unitdata?: any;
}

interface Field {
  id?: number | string;
  material_id?: any;
  unit_id?: string;
  quantity?: any;
  rate?: any;
  gst?: any;
  gst_amt?: any;
  total_amount?: any;
  unit_title: string;
  quotation_id: string;
}

interface FormData {
  contractor_id: string;
  mobile_number: string;
  email: string;
  q_date: string | Date;
}
const CreateQuotation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [materialList, setMaterialList] = useState<Material[]>([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<Field[]>([
    {
      material_id: '',
      unit_id: '',
      unit_title: '',
      quantity: '',
      rate: '',
      gst: '',
      gst_amt: '',
      total_amount: '',
      quotation_id: ''
    }
  ]);

  const [formData, setFormData] = useState<FormData>({
    contractor_id: '',
    mobile_number: '',
    email: '',
    q_date: ''
  });

  useEffect(() => {
    if (id) {
      fetchQuotation();
    }
  }, []);

  const fetchQuotation = async () => {
    const response = await getService(`contractorchallan/${id}`);
    if (response.status === 200) {
      // const QuotationDetail = response.data.quotationdetail;
      setFormData(prevFields => ({
        ...prevFields,
        contractor_id: response?.data[0].contractor_id,
        mobile_number: response.data[0].mobile_number,
        email: response.data[0].email,
        q_date: response.data[0].q_date
      }));
      if (response.data[0].challandetail.length < 1) {
        setFields(prevFields => {
          const updatedFields = fields.map((detail: any) => ({
            material_id: '',
            unit_id: '',
            unit_title: '',
            quantity: '',
            rate: '',
            gst: '',
            gst_amt: '',
            total_amount: '',
            quotation_id: ''
          }));
          return updatedFields;
        });
      } else {
        setFields(prevFields => {
          const updatedFields = response.data[0].challandetail.map(
            (detail: any) => ({
              material_id: detail.material_id.toString(),
              unit_id: detail.unit_id.toString(),
              unit_title: detail.unit.unit_title,
              quantity: detail.quantity.toString(),
              rate: detail.rate.toString(),
              gst: detail.gst,
              gst_amt: detail.gst_amt.toString(),
              total_amount: detail.total_amount.toString(),
              quotation_id: detail.id.toString()
            })
          );
          return updatedFields;
        });
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleContractorChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const updatedFormData = { ...prevState, [name]: value };
      if (updatedFormData.contractor_id != '0') {
        getService(`contractor/${updatedFormData.contractor_id}`).then(
          response => {
            if (response.status === 200) {
              setFormData(prevState => ({
                ...prevState,
                mobile_number: response.data[0]?.mobile_no,
                email: response.data[0]?.email
              }));
            }
          }
        );
      } else {
        setFormData(prevState => ({
          ...prevState,
          mobile_number: '',
          email: ''
        }));
      }
      return updatedFormData;
    });
  };
  const handleAddField = () => {
    const newObj: Field = {
      material_id: '',
      unit_id: '',
      quantity: '',
      rate: '',
      gst: '',
      gst_amt: '',
      total_amount: '',
      unit_title: '',
      quotation_id: ''
    };
    // Add newObj to originalArray
    // const originalfield: Field[] = fields.concat(newObj);
    const originalfield: Field[] = [newObj, ...fields];
    setFields(originalfield);
  };

  const handleRemoveField = async (indexToRemove: number, id?: any) => {
    // Filter out the object to remove based on its index
    const updatedFields: Field[] = fields.filter(
      (_, index) => index !== indexToRemove
    );
    // Update the state with the filtered array
    setFields(updatedFields);
    // if (id) {
    //   await deleteService(`site_inventory/${id}`);
    // }
  };

  const addData = async () => {
    const filterData = id
      ? fields.find(item => item.quotation_id === '')
      : null;

    if (filterData) {
      filterData.quotation_id = id ?? ''; // Use nullish coalescing operator
    }
    console.log('$$$$', filterData);
    const response = await addService(`material_quotation`, filterData);
    if (response.status === 200) {
      fetchQuotation();
      toast.success('Data Added');
    } else {
      validationErrorFromAPI(response.data);
    }
  };

  const updateData = async (indexToRemove: number, id?: any) => {
    // const filterData = id ? fields.filter(item => item.id === id) : [];
    const filterData = id
      ? fields.find(item => item.quotation_id === id.toString())
      : null;
    const response = await addService(`material_quotation/${id}`, filterData);
    if (response.status === 200) {
      toast.success('Data Update!');
    } else {
      validationErrorFromAPI(response.data);
    }
  };

  const deleteData = async (indexToRemove: number, id?: any) => {
    const response = await deleteService(`material_quotation/${id}`);
    if (response.status === 200) {
      toast.success('Material Deleted!');
      fetchQuotation();
      console.log('...', response.data);
    }
    console.log('...', indexToRemove, id);
  };

  const handleSelect = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { value } = event.target;
    const foundMaterial = materialList.find(material => material.id == value);
    console.log('foundMaterial', foundMaterial);
    // Make a shallow copy of the array of fields
    const fieldset = [...fields];
    // // Make a shallow copy of the specific field object within the array
    const updatedField = { ...fieldset[index] };
    // // Update the specific property within the copied field object
    updatedField['material_id'] = value;
    updatedField['unit_title'] = foundMaterial?.unitdata?.unit_title;
    updatedField['unit_id'] = foundMaterial?.unitdata?.id?.toString();
    // // // Update the array with the updated field object at the specified index
    fieldset[index] = updatedField;
    // // // Set the state with the updated array
    setFields(fieldset);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const fieldset = [...fields];
    const updatedField = { ...fieldset[index] };
    const fieldName = name as keyof Field;
    updatedField[fieldName] = value;

    if (
      fieldName === 'quantity' ||
      fieldName === 'rate' ||
      fieldName === 'gst'
    ) {
      const stock = parseFloat(updatedField['quantity']) || 0;
      const purchaseRate = parseFloat(updatedField['rate']) || 0;
      const gst = parseFloat(updatedField['gst']) || 0;

      const site_rate = stock * purchaseRate;
      const gstAmount = (site_rate * gst) / 100;
      const totalAmount = site_rate + gstAmount;

      // updatedField['site_rate'] = site_rate.toFixed(2);
      updatedField['gst_amt'] = gstAmount.toFixed(2);
      updatedField['total_amount'] = totalAmount.toFixed(2);
    }

    fieldset[index] = updatedField;
    setFields(fieldset);
  };

  const handleDateChanges = (date: any) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;
    setFormData(prevFormData => ({
      ...prevFormData,
      q_date: formattedDate
    }));
  };

  useEffect(() => {
    fetchSupplier();
    fetchMaterial();
  }, []);

  const fetchSupplier = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setSupplierList(response.data);
    }
  };
  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };

  const isObjectComplete = (obj: any) => {
    return Object.values(obj).every((value: any) => value.trim() !== '');
  };

  const handleSubmit = async () => {
    const filterData = fields.map((obj: any) => {
      delete obj.quotation_id;
      return obj;
    });
    // const filteredFields = fields.map(field => {
    //   const { unit, ...rest } = field;
    //   return rest;
    // });
    const filteredFields = id ? fields : filterData.filter(isObjectComplete);
    console.log('filteredFields', filteredFields);
    if (filteredFields.length === 0) {
      toast.error('Please fill all the fields');
    } else {
      const combinedData = {
        ...formData,
        q_material: JSON.stringify(filteredFields)
      };

      console.log('combinedData', combinedData);
      setLoading(true);
      const response = id
        ? await addService(`contractorchallan/${id}`, combinedData)
        : await addService('contractorchallan', combinedData);
      if (response?.status === 200) {
        setLoading(false);
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        navigate('/quotationListView');
      } else if (response?.status === 500) {
        setLoading(false);
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      <h2 className="mb-4">Create Challan</h2>
      <Row>
        <Col xs={12} xl={12}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingSelectTask" label="Contractor">
                <Form.Select
                  name="contractor_id"
                  value={formData.contractor_id}
                  onChange={handleContractorChange}
                >
                  <option>Select Contractor</option>
                  {supplierList.length > 0 &&
                    supplierList.map(item => (
                      <option key={item.id} value={item.id}>
                        {`${item.first_name} ${item.last_name}`}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            {/* <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingInputGrid" label="Person Name">
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col> */}
            <Col sm={6} md={2}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Mobile Number"
              >
                <Form.Control
                  name="mobile_number"
                  type="tel"
                  placeholder="Mobile Number"
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                  maxLength={10}
                  onKeyDown={handleKeyDown}
                  pattern="[0-9]*"
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <FloatingLabel controlId="floatingInputGrid" label="Email">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={2}>
              <DatePicker
                value={formData['q_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  handleDateChanges(date);
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <div>
              <Col xs={12} xl={12}>
                {fields.map((field, index) => {
                  return (
                    <Row as="form" className="g-3 mb-6" key={index}>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingSelectPrivacy"
                          label="Material"
                        >
                          <Form.Select
                            value={fields[index]['material_id']}
                            onChange={event => handleSelect(event, index)}
                          >
                            <option>Select Material</option>
                            {materialList.length > 0 &&
                              materialList.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Unit"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="unit_title"
                            value={fields[index]['unit_title']}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Quantity"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Project title"
                            name="quantity"
                            value={fields[index]['quantity']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Rate"
                        >
                          <Form.Control
                            type="number"
                            placeholder="number"
                            name="rate"
                            value={fields[index]['rate'] || 0}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={1}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST %"
                        >
                          <Form.Control
                            type="number"
                            placeholder="GST"
                            name="gst"
                            value={fields[index]['gst'] || 0}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="GST Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="gst_amt"
                            value={fields[index]['gst_amt']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col sm={4} md={2}>
                        <FloatingLabel
                          controlId="floatingInputGrid"
                          label="Total Amount"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            disabled
                            name="total_amount"
                            value={fields[index]['total_amount']}
                            onChange={event => handleChange(event, index)}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs="auto">
                        <Button
                          variant="primary"
                          className="px-1"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          onClick={() =>
                            index === 0
                              ? handleAddField()
                              : handleRemoveField(index, field.id)
                          }
                        >
                          {index === 0 ? '+' : '-'}
                        </Button>
                      </Col>
                      {!field.quotation_id && (
                        <Col xs="auto">
                          <Button
                            variant="info"
                            className="px-1"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            onClick={() => addData()}
                          >
                            {'Add'}
                          </Button>
                        </Col>
                      )}
                      {field.quotation_id && (
                        <Col xs="auto">
                          <Button
                            variant="info"
                            className="px-1"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            onClick={() =>
                              updateData(index, field.quotation_id)
                            }
                          >
                            {'Update'}
                          </Button>
                        </Col>
                      )}
                      {field.quotation_id && (
                        <Col xs="auto">
                          {/* <Button
                              variant="danger"
                              className="px-1"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              onClick={() =>
                                deleteData(index, field.quotation_id)
                              }
                            >
                              {'Delete'}
                            </Button> */}
                          <Button
                            size="sm"
                            variant="link"
                            className="text-body-quaternary text-body-tertiary-hover me-2"
                            onClick={() =>
                              deleteData(index, field.quotation_id)
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </Col>
            </div>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/quotationListView">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateQuotation;
