import {
  faPaperclip,
  faPlus,
  faRemove
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';
import {
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface Field {
  investor_id: string;
  invoice_no: string;
  invoice_date: any;
  note: string;
  gst_amount: string;
  total_amount: string;
  invoice_document: any;
}

const AddPurchasing: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [country, setCountry] = useState<Common[]>([]);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [checkedItems, setCheckedItems] = useState<number[]>([0]);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState<Field>({
    investor_id: '',
    invoice_no: '',
    invoice_date: '',
    note: '',
    gst_amount: '',
    total_amount: '',
    invoice_document: ''
  });

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setFields(prevFields => ({
        ...prevFields,
        invoice_document: file
      }));
    }
  };

  const handleCheckBox = (id: any) => {
    setCheckedItems(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  useEffect(() => {
    getSupplier();
  }, []);

  const getSupplier = async () => {
    const response = await getService('investor');
    setSupplierList(response.data);
  };
  useEffect(() => {
    if (id) {
      fetchSupplierDetails(id);
    }
  }, [id]);

  const fetchSupplierDetails = async (id: any) => {
    const response = await getService(`investor_invoice/${id}`);
    if (response.status === 200) {
      const arr = response.data.status.split(',').map(Number);
      setCheckedItems(arr);
      const totalAmount = parseFloat(response.data.total_amount) || 0; // Fallback to 0
      const gstAmount = parseFloat(response.data.gst_amount) || 0; // Fallback to 0
      setFields(prevFields => ({
        ...prevFields,
        investor_id: response.data.investor_id,
        invoice_no: response.data.invoice_no,
        invoice_date: response.data.invoice_date,
        note: response.data.note,
        gst_amount: response.data.gst_amount,
        total_amount: response.data.total_amount,
        invoice_document: response.data.invoice_document,
        status: response.data.status,
        amount_wo_gst: totalAmount - gstAmount
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const sortData = checkedItems.sort();
    const removeZero = sortData.filter((item: any) => item !== 0);
    const invoiceType =
      removeZero.length > 0 ? removeZero.join() : checkedItems.join();
    const withStatus = { ...fields, status: invoiceType };

    let response: any = {};
    const endpoint = id ? `investor_invoice/${id}` : 'investor_invoice';

    try {
      setLoading(true);
      response = await addService(endpoint, withStatus);

      if (response?.status === 200) {
        setLoading(false);
        toast.success('Successfully Added', {
          position: 'top-right',
          autoClose: 5000,
          theme: 'light'
        });
        navigate('/purchasingListView');
      } else if (response?.status === 500) {
        setLoading(false);
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    } catch (error) {
      toast.error('An unexpected error occurred.', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'light'
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4">{id ? 'EDIT' : 'ADD'} Invoice</h2>

      <Form>
        <Row>
          <Col xs={12} xl={9}>
            <Row as="form" className="g-3 mb-6">
              <Col sm={4} md={4}>
                <FloatingLabel
                  controlId="floatingSelectCity"
                  label="Investor Name"
                >
                  <Form.Select
                    name="investor_id"
                    value={fields['investor_id']}
                    onChange={handleSelect}
                  >
                    <option>Select Investor</option>
                    {supplierList.length > 0 &&
                      supplierList.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.investor_name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={4} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Invoice No">
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    name="invoice_no"
                    value={fields['invoice_no']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={4} md={4}>
                <DatePicker
                  // value={selectedEndDate}
                  value={fields['invoice_date']}
                  options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                  onChange={([date]) => {
                    setFields({
                      ...fields,
                      invoice_date: formatDateForAPI(date)
                    });
                  }}
                  render={(_, ref) => {
                    return (
                      <Form.Floating>
                        <Form.Control
                          placeholder="Project title"
                          ref={ref}
                          id="startDate"
                        />
                        <label htmlFor="startDate" className="ps-6">
                          Invoice date
                        </label>
                      </Form.Floating>
                    );
                  }}
                />
              </Col>
              <Col sm={4} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="GST Amount">
                  <Form.Control
                    type="number"
                    placeholder="GST Amount"
                    name="gst_amount"
                    value={fields['gst_amount']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>{' '}
              <Col sm={4} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Total Amount"
                >
                  <Form.Control
                    type="number"
                    placeholder="Mobile Numbar"
                    name="total_amount"
                    value={fields['total_amount']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={4} md={4}>
                <div className="d-flex align-items-center border rounded-2 pl-2 cursor-pointer">
                  <div className="p-2 d-flex align-items-center">
                    <label
                      className="text-body fs-9 cursor-pointer"
                      htmlFor="attachments"
                    >
                      <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                      <span className="ml-2">Upload Image</span>
                    </label>
                  </div>
                  <Form.Control
                    className="d-none"
                    type="file"
                    id="attachments"
                    onChange={handleImageChange}
                  />
                </div>
                {image && (
                  <div className="mt-3 border rounded position-relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Uploaded"
                      style={{ maxWidth: '100%' }}
                    />
                    <FontAwesomeIcon
                      onClick={() => setImage(null)}
                      icon={faRemove}
                      className="position-absolute top-0 end-0 m-1 bg-black px-1 rounded-2"
                    />
                  </div>
                )}
              </Col>
              <Col xs={12} className="gy-6">
                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label="Comments"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}
                    name="note"
                    value={fields['note']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              {/* <Col xs={12} className="gy-6 d-flex">
                <Col xs={6} className="gy-6 d-flex px-2">
                  <Form className="d-flex gap-5">
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked1"
                      label={
                        <Button
                          onClick={() => handleCheckBox(1)}
                          variant="success"
                        ></Button>
                      }
                      checked={checkedItems.includes(1)}
                      onChange={() => handleCheckBox(1)}
                    />
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked2"
                      label={
                        <Button
                          onClick={() => handleCheckBox(2)}
                          variant="warning"
                        ></Button>
                      }
                      checked={checkedItems.includes(2)}
                      onChange={() => handleCheckBox(2)}
                    />
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked3"
                      label={
                        <Button
                          onClick={() => handleCheckBox(3)}
                          variant="danger"
                        ></Button>
                      }
                      checked={checkedItems.includes(3)}
                      onChange={() => handleCheckBox(3)}
                    />
                  </Form>
                </Col>
                <Col xs={6} className="d-flex">
                  <Link to="/purchasingListView" className="px-12">
                    <Button variant="phoenix-primary" className="px-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-5 px-sm-15"
                    onClick={handleSave}
                  >
                    Submit
                  </Button>
                </Col>
              </Col> */}
              <Col xs={12} className="gy-6 d-flex flex-column flex-sm-row">
                <Col xs={12} sm={6} className="gy-6 d-flex px-2">
                  <Form className="d-flex gap-3 flex-wrap">
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked1"
                      label={
                        <Button
                          onClick={() => handleCheckBox(1)}
                          variant="success"
                        ></Button>
                      }
                      checked={checkedItems.includes(1)}
                      onChange={() => handleCheckBox(1)}
                    />
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked2"
                      label={
                        <Button
                          onClick={() => handleCheckBox(2)}
                          variant="warning"
                        ></Button>
                      }
                      checked={checkedItems.includes(2)}
                      onChange={() => handleCheckBox(2)}
                    />
                    <Form.Check
                      type="checkbox"
                      id="flexCheckChecked3"
                      label={
                        <Button
                          onClick={() => handleCheckBox(3)}
                          variant="danger"
                        ></Button>
                      }
                      checked={checkedItems.includes(3)}
                      onChange={() => handleCheckBox(3)}
                    />
                  </Form>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  className="ms-5"
                  // className="d-flex justify-content-between align-items-center px-2"
                >
                  <Link to="/purchasingListView">
                    <Button variant="phoenix-primary" className="px-6 ms-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-8 ms-5"
                    onClick={handleSave}
                  >
                    Submit
                  </Button>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddPurchasing;
